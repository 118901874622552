import { Component, OnInit, QueryList, ViewChildren, ViewContainerRef, } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import { LocaleService } from "src/app/services/locale.service";

@Component({
  selector: 'app-grid-time-editor',
  templateUrl: './grid-time-editor.component.html',
  styleUrls: ['./grid-time-editor.component.scss']
})
export class GridTimeEditorComponent implements ICellEditorAngularComp {

  public inputDate: any;
  public params: any;
  @ViewChildren('input', { read: ViewContainerRef })

  public inputs: QueryList<any>;
  public fieldName: any;
  // private focusedInput: number = 0;

  constructor(private localeService: LocaleService) {}

  agInit(params: any): void {
    this.params = params
    this.fieldName = this.params.colDef.field;
    this.inputDate = params.value;
  }

  ngAfterViewInit() {
    if (this.fieldName != 'checkBox') {
      this.focusOnInputNextTick(this.inputs.first);
    }
  }

  private focusOnInputNextTick(input: ViewContainerRef) {
    window.setTimeout(() => {
      input.element.nativeElement.focus();
    }, 0);
  }

  getValue() {
    return this.inputDate;
  }

  checkedHandler(event) {
    let checked = event.target.checked;
    let colId = this.params.column.colId;
    this.params.node.setDataValue(colId, checked);
  }

  onSelect(event) {
    this.params.api.stopEditing(false);
  }

  disableTime(e: any) {
    if (e.which != 13 && e.which != 27 && e.which != 9)  // This function disables the user from manually entering values. We are allowing only Enter keypress to bypass this function. The ASCII code for Enter key is 13.
      return false;
  }
}
