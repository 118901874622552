// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  configFileUrlPath: '/assets/config/app.config.json',
  AZURE_TENANT_ID : '40e336d2-01d8-4231-bea7-1876d1513843',
  AZURE_CLIENT_ID: '2b5702e2-6dd5-4e39-a06c-d5fe9379fa3a',
  AZURE_CLIENT_SECRET: 'tENHS7d/Du1TgO2iF8q2kSPWN9OD9iIqtt/1fvXUt7Y=',
  clientScope: 'openid',
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
