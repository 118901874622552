import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router, RouterEvent} from '@angular/router';
import {  NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DetailsHeaderViewModel } from 'projects/rfp-list/src/app/models/details-header-view-model';
import { RfpDetailsService } from 'projects/rfp-list/src/app/services/rfp-details.service';
import { Subject, Subscription } from 'rxjs';
import { DfModalService } from '@design-factory/design-factory';
import { takeUntil } from 'rxjs/operators';
import { ProposalProviders } from 'projects/rfp-list/src/app/models/proposal-choice.model';

@Component({
  selector: 'app-proposal-providers',
  templateUrl: './proposal-providers.component.html',
  styleUrls: ['./proposal-providers.component.scss']
})
export class ProposalProvidersComponent implements OnInit {
  @Input() proposalProviderData:  ProposalProviders[] = [];
  @Input() rfpId: string;
  @Input() error: string;
  private readonly rfpDetailsService: RfpDetailsService;
  rfpDetailsHeaderData: DetailsHeaderViewModel;
  modalUserPicker: NgbModalRef;
  navbarCollapsed = true;
  subTabActive: string;
  active: string;
  pageType: string = "proposalDetails";
  proposalDetailsHeaderData: DetailsHeaderViewModel;
  errorMessage : string ='';
  unSubscribe$ = new Subject();
  errors$ = new Subject<any>();
  private subscription: Subscription;
  public isProposalProvidersLoading: boolean = false;
  public crossClickData: string='Cross click';
  public isVisible: boolean = true;
  
  constructor( 
  public activeModal: NgbActiveModal,
  private readonly router: Router,
  private RfpDetailsService: RfpDetailsService,
  private modalService: DfModalService,
  private readonly activatedRoute: ActivatedRoute
  ) { }
 
ngOnInit(): void {
  this.activatedRoute.params.pipe(takeUntil(this.unSubscribe$)).subscribe(x => {
    let channelId = 0
    this.subscription = this.activatedRoute.queryParams.pipe(takeUntil(this.unSubscribe$)).subscribe((params: Params) => {
    channelId = params['channelId']
    });
   
    let rfpID = x.rfpId;
    if(rfpID != undefined && channelId == undefined)
    {
      this.isProposalProvidersLoading = true;
      this.isVisible = false;
      this.rfpId = rfpID;
      this.RfpDetailsService.getProposalProviders(rfpID)
      .toPromise().then((response) => {
        this.isProposalProvidersLoading = false;
        let ProposalChoiceDetails = response.data.proposalProviders?.map((ProposalChannels) =>  {
          if (ProposalChannels && ProposalChannels.name != null) {
            let ProposalChannelsname: ProposalProviders = {} as ProposalProviders;
            ProposalChannelsname.id = ProposalChannels.id;
            ProposalChannelsname.name = ProposalChannels.name;
            ProposalChannelsname.url= ProposalChannels.url;
            return ProposalChannelsname;
          }
        }); 
        if(ProposalChoiceDetails.length > 1)
          {
            this.proposalProviderData = ProposalChoiceDetails;
          } 
      });
    }
    else if(rfpID != undefined && channelId != undefined)
      {
        this.isProposalProvidersLoading = true;
          this.isVisible = false;
          this.RfpDetailsService.getProposalProviders(rfpID,channelId)
          .toPromise().then((response) => {
            this.isProposalProvidersLoading = false;
           response.data.proposalProviders?.map((proposalChannels) =>  {
              if (proposalChannels.url != "") {
                this.router.navigate([]).then(() => { window.open(proposalChannels.url); });
              }
            });          
            }).catch((error)=>{
                if(error){
                  this.isProposalProvidersLoading = false;
                  if (error.status === 404) {
                    let queryParams: Params;
                    queryParams = {
                      tab: 'propose',
                      subTab: 'propose-detail'
                    };
                    this.router.navigate(['rfpdetails/propose/' + this.rfpId], { queryParams: queryParams, queryParamsHandling: 'merge' });
                    }
                  else
                  {
                    this.isProposalProvidersLoading = false;
                    this.errorMessage = error.error.errors[0].detail;
                    this.crossClickData="Error";                    
                  }
                }
            });
      }
    })
        
  if(this.error && this.error !=="")
    {
        this.isProposalProvidersLoading = false;
        this.errorMessage = this.error;
        this.crossClickData="Error";
    };
} 

SourceChannelCliked(){
  let queryParams: Params;
  queryParams = {
    tab: 'propose',
    subTab: 'propose-detail'
  };
  this.router.navigate(['rfpdetails/propose/' + this.rfpId], { queryParams: queryParams, queryParamsHandling: 'merge' });
}

ThirdPartyChannelCliked(channelId: number)
{
  this.isProposalProvidersLoading = true;
  this.RfpDetailsService.getProposalProviders(this.rfpId,channelId)
  .pipe(takeUntil(this.unSubscribe$))
  .subscribe(response => {
    this.isProposalProvidersLoading = false;
    response.data.proposalProviders?.map((proposalthirdpartydata) =>  {
      if (proposalthirdpartydata.url.includes('meetingbroker.com/'))
      {
        const tabActive: string = 'rfp';
        const subTabActive: string = 'rfpDetail';
        const queryParams: Params = { tab: tabActive, subTab: subTabActive };
        this.router.navigate(['rfpdetails/propose/' + this.rfpId], { queryParams: queryParams, queryParamsHandling: 'merge' });
      }
      else
      {
        this.router.navigate([]).then(() => { window.open(proposalthirdpartydata.url);});
      }
    }
  );
  },
    (error) => {
      this.isProposalProvidersLoading = false;
      this.errorMessage = error.error.errors[0].detail;
      this.crossClickData="Error";
    });
}
}
