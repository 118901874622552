import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { OidcLoginComponent } from './components/oidc-login/oidc-login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PageUnderProgressComponent } from './components/page-under-progress/page-under-progress.component';
import { CreateMessagePageComponent } from './components/create-message-page/create-message-page.component';
import { HELP_CONFIG_INJECTOR } from './services/helpconfig.token';
import { helpConfig } from 'src/assets/help/helpConfig';
import { ProposalProvidersComponent } from 'projects/rfp-list/src/app/proposal-details/proposal-providers/proposal-providers.component';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('projects/home/src/app/home.module').then(x => x.HomeModule) },
  { path: 'createmessage', component: CreateMessagePageComponent},
  { path: 'rfplist', loadChildren: () => import('projects/rfp-list/src/app/rfp-list/rfp.module').then(x => x.RfpModule) },
  { path: 'rfpdetails', loadChildren: () => import('projects/rfp-list/src/app/rfp-details/rfp-details.module').then(x => x.RfpDetailsModule) },
  { path: 'proposalchoice/:rfpId', component: ProposalProvidersComponent, canActivate: [AuthGuard]},
  { path: 'messagelist', loadChildren: () => import('projects/message/src/app/message.module').then(x => x.MessageModule) },  
  { path: 'user', loadChildren: () => import('projects/user/src/app/user.module').then(x => x.UserModule) },  
  //{ path: 'messageDetails', loadChildren: () => import('projects/message/src/app/message.module').then(x => x.MessageModule) },
  { path: 'login-callback', component: LoginComponent},
  { path: 'login', component: OidcLoginComponent },
  { path: 'not-found', component: PageNotFoundComponent, canActivate: [AuthGuard] },
  { path: 'under-progress', component: PageUnderProgressComponent, canActivate: [AuthGuard] },
  { path: 'error', component: ErrorPageComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    // DO NOT IMPORT LAZY LOADED MODULES
    // https://angular.io/guide/styleguide#never-directly-import-lazy-loaded-folders 
    
    // RfpModule.forRoot(),
    // MessageModule.forRoot()
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: HELP_CONFIG_INJECTOR,
      useValue: helpConfig
    }]
})
export class AppRoutingModule { }
