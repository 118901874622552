import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppConfiguration } from './app-configuration';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppConfigService <T extends IAppConfiguration = IAppConfiguration> {

public static appConfig:IAppConfiguration;
  static http: any;
  constructor() {}

  static loadConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', (resp) => {
        if (oReq.status === 200) {
          try {
            AppConfigService.appConfig = JSON.parse(oReq.responseText);
          } catch (e) {
            reject(e);
          }
          resolve();
        } else {
          reject(oReq.statusText);
        }
      });
      oReq.open('GET', environment.configFileUrlPath);
      oReq.send();
    });
  }

    /**
   * Returns the loaded configuration object for this application.
   */
   getConfig() {
    return AppConfigService.appConfig as T;
  }
}
