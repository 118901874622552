import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent {
  headingId = 'modal-title-id';

  type: string;
  title: string;
  message: string;
  okButtonText: string;
  cancelButtonText: string;
  isHeaderRequired: boolean;
  isCancelButtonEnabled: boolean;
  isCancelButtonVisible: boolean;
  isFooterVisible:boolean;

  constructor(public activeModal: NgbActiveModal) { }

  cancel() {
    if(!this.isFooterVisible) return;
    this.activeModal.dismiss('cancel');
  }

  ok() {
    this.activeModal.close('ok');
  }

}
