import { Injectable, OnDestroy } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, of, throwError, timer } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, map, mergeMap, retryWhen, takeUntil, tap } from "rxjs/operators";
import { SubscriptionContext } from "../models/subscription-list.model";
import { AppConfigService } from "../helpers/configuration/app-config.service";
import { User } from "oidc-client-ts";
import { SubscriptionListService } from "../services/subscription-list.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy{

    tokenExpirationInSeconds: number;
    subscriptionContext: SubscriptionContext;
    unSubscribe$ = new Subject();

    constructor(private authService: AuthenticationService, private configService: AppConfigService, private sublistService: SubscriptionListService)
    {
        let config = this.configService.getConfig();
        let appConfigValues = JSON.parse(config.applicationConfigurations);
        this.tokenExpirationInSeconds =  appConfigValues['tokenExpirationSeconds'];
        this.sublistService.selectedSubscription$.pipe(takeUntil(this.unSubscribe$)).subscribe(sub => this.subscriptionContext = sub);
    }
    
    ngOnDestroy(): void {
        this.unSubscribe$.next();
        this.unSubscribe$.complete();
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = this.authService.currentUserValue;
        let sessionExpiryDateForToken = sessionStorage.getItem('expiryDate');
        let isTokenRefreshing =  JSON.parse(sessionStorage.getItem('isTokenRefreshing'));
        if(sessionExpiryDateForToken != null && currentUser != null && !isTokenRefreshing)
        {
            this.setRefreshToken(sessionExpiryDateForToken);
            currentUser = this.authService.currentUserValue;
            if(currentUser && currentUser.access_token != null && !request.url.endsWith('/token')) {

                var refreshedToken = sessionStorage.getItem("currentUserToken");
                refreshedToken = refreshedToken == null ? currentUser.access_token : refreshedToken;
                request = this.addRequestHeaders(request, refreshedToken);
            }
        }
        else
        {
            if(currentUser && currentUser.access_token != null && !request.url.endsWith('/token')) {
                request = this.addRequestHeaders(request, currentUser.access_token);
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if(event instanceof HttpResponse){
                }
                return event;

             })
        )
    }

    addRequestHeaders(request: HttpRequest<any>, accessToken: string) : HttpRequest<any>
    {
        var req = request.clone({headers:request.headers.set('Authorization',`Bearer ${accessToken}`)});
            req = req.clone({headers:req.headers.set('X-Frame-Options','DENY')});
            req = req.clone({headers:req.headers.set('Cache-Control','no-store')});
            req = req.clone({headers:req.headers.set('Pragma','no-cache')});
            req = req.clone({headers:req.headers.set('X-Content-Type-Options','nosniff')});
            req = req.clone({headers:req.headers.set('X-XSS-Protection','1; mode=block')});
            req = req.clone({headers:req.headers.set('Vary','Origin')});
            req = req.clone({headers:req.headers.set('Referrer-Policy','strict-origin-when-cross-origin')});
            req = req.clone({headers:req.headers.set('Content-Security-Policy','default-src')});
            req = req.clone({headers:req.headers.set('Access-Control-Allow-Origin','https://Amadeus-hospitality.com')});

            // Only send subscription_guid header if the user is a multi sub user and the selected subscription is different from the default subscription
            if (this.subscriptionContext.isMultiSubUser) {
                req = req.clone({headers:req.headers.set('subscription_guid', this.subscriptionContext.selectedSubscription)});
            }
            return req;
    }

    setRefreshToken(sessionExpiryDateForToken: string) {        
        let expiredDate = new Date(sessionExpiryDateForToken).getTime();
        // start refreshing when token is half way (or more) expired. 
        let refreshDate = new Date(expiredDate - (Math.floor(this.tokenExpirationInSeconds / 2) * 1000)).getTime();
        let currentDate = Date.now();
        // only refresh token if current date is between the refresh date and expiration date
        // no need to refresh token here if it is already expired as the error interceptor will refresh token and resubmit the request
        if (expiredDate > currentDate && currentDate > refreshDate) {
            sessionStorage.setItem('isTokenRefreshing', 'true');
            this.authService.renewToken().subscribe((user: User) => {
                this.authService.setCurrentUser(user);
                sessionStorage.setItem('isTokenRefreshing', 'false');
            });
        }
    }
}
