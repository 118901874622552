import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandler } from '../handlers/global-error-handler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '../interceptors/http-error.interceptor';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],

  providers:[    
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ]

})
export class ErrorHandlerModule { }
