import { Injectable } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Features, PageRoles } from "../models/app-permission.model";
import { MbRole } from "../models/mb-role";

const userRoles = "userRoles";

export interface roles {
  roleDefinition: {
    roleName: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class AppPermissionsService {
  private roles = new ReplaySubject<roles[]>(1);

  constructor() {
    let storedRoles = sessionStorage.getItem(userRoles);
    if (storedRoles) this.setUserRoles(JSON.parse(storedRoles), false);
  }

  setUserRoles(roles: any, storeProp: boolean = true) {
    if (storeProp) sessionStorage.setItem(userRoles, JSON.stringify(roles));
    this.roles.next(roles);
  }

  getUserRoles() {
    return this.roles;
  }

  getUserRolesByFeature(feature: Features) {
    var userRoles: MbRole[] = [];
    let roles = PageRoles[feature];
    if(roles){
      userRoles = roles;
    }    
    return userRoles;
  }
}
