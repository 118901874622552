import { CurrencyPipe, formatCurrency } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';

@Injectable()
@Pipe({
  name: 'localizedCurrency'
})
export class LocalizedCurrencyPipe implements PipeTransform {

  constructor(private localeService: LocaleService) { }

  transform(
    value: number | any,
    currencySymbol: string = '',
    currencyCode?: string,
    digitsInfo?: string,
    locale?: string): any {

    if (value == null || value === '' || value !== value) {
      return null;
    }

    locale = $localize.locale; 
    
    return formatCurrency(
      Number(value),
      locale,
      currencySymbol,
      currencyCode,
      digitsInfo
    );
  }

}
