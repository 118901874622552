import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Injectable({
  providedIn: 'root'
})
export class AppinsightsService {
  public appInsights: ApplicationInsights;

  constructor() { }

  initialise() {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: sessionStorage.getItem('ApplicationInsightKey'),
        enableAutoRouteTracking: true,
        isCookieUseDisabled: true,
        isStorageUseDisabled: true,
        autoTrackPageVisitTime: true
      }})
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.loadCustomTelemetryProperties();
  }

  public logInfo(information:string){
    if(this.appInsights == undefined && sessionStorage.getItem('ApplicationInsightKey'))
    {
       this.initialise();
       this.appInsights.trackTrace({message: information});
    }
    else(!sessionStorage.getItem('ApplicationInsightKey'))
    {
      console.log(information);
    }
  }

  logException(error : Error){
    let exception = {
      exception : error
    };
    if(this.appInsights == undefined && sessionStorage.getItem('ApplicationInsightKey'))
    {
      this.initialise();
      this.appInsights.trackException(exception);
    }
    else(!sessionStorage.getItem('ApplicationInsightKey'))
    {
      console.log(error);
    }
  }

  private loadCustomTelemetryProperties()
  {
    this.appInsights.addTelemetryInitializer(envelope=>{
      var item=envelope.baseData;
      item.properties=item.properties || {};
      item.properties["ApplicationName"]="Ah.Nextgen.MB.Ui";
    })
  }

  logPageView(name?: string, url?: string) {
    if(this.appInsights == undefined && sessionStorage.getItem('ApplicationInsightKey'))
    {
      this.initialise();
    }
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }
}
