<app-spinner *ngIf='isLoading'></app-spinner>

<div *ngIf="!authService.userLoggedOut">
  <app-navbar-header [isSubscriptionSet]="isSubscriptionSet" [currentPageId]="currentPageId"
    [user]="user" (helpCollapsedEmitter)="setHelpVisibility($event)"></app-navbar-header>
  <div class="df-sidenav-wrapper d-flex flex-grow-1" [class.no-navbar]="!isLoggingOut">
    <df-sidenav *ngIf="isLoggedIn" [dfSideNavCollapse]="sideNavService.isCollapsed$ | async" id="df-sidenav"></df-sidenav>
    <main [ngClass]="isHelpDisplayed ? 'help-visible' : 'help-hidden'" class="d-flex df-scroll-content df-main-content w-100">
      <div id="content-page" class="df-scroll-content df-main-content-wrapper">
        <router-outlet></router-outlet>
        <app-copyright *ngIf="isSubscriptionSet" class="copyright"></app-copyright>
      </div>
    </main>
  </div>
</div>