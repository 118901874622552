<app-spinner id="responder" *ngIf='isLoading'></app-spinner>
<div *ngIf="!isLoader">
  <ngb-alert dfInsertAlertIcon *ngIf="alertmessage" type="{{alerttype}}" (closed)="this.alertmessage = null">{{
    alertmessage}}
  </ngb-alert>
  <div class="login-container" *ngIf="!isLoader && isLoggedIn && subscriptionCount > 1" >
    <div class="common-div-login-footer row">
      <div [class]="'loginForm'">
        <form>
          <img src="assets/img/amadeus_logo.jpg" class="image-container" alt="Responsive image">

          <h2 class="subscription-subheader">{{'subscriptionlist-subheader-welcome-text' | translate: 'Welcome'}}
            {{userInfo.name}}</h2>

          <div class="mb-5">
            <app-subscription-list (subscriptionSelectedEvent)="onSubscriptionSelect($event)"></app-subscription-list>
          </div>
        </form>
      </div>
      <div class="footer-alignment">
        <app-privacy-terms></app-privacy-terms>
        <app-copyright></app-copyright>
      </div>
    </div>
  </div>
</div>