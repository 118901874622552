import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-under-progress',
  templateUrl: './page-under-progress.component.html',
  styleUrls: ['./page-under-progress.component.scss']
})
export class PageUnderProgressComponent implements OnInit {

  pageUnderProgressHeader: string;
  pageUnderProgressMessage: string;

  constructor() { }

  ngOnInit() {
    this.pageUnderProgressHeader = $localize `Page Under Construction`;
    this.pageUnderProgressMessage = $localize `Please StandBy`;
    sessionStorage.clear();
  }
}
