import { Meta } from '@angular/platform-browser';
import { ApiResponse, ErrorObject } from './api-response.model';

export interface GetRfpDetailsResponseModel extends ApiResponse {
  data: ResponseData;
}

export interface ResponseData {
  rfp: RfpDetails;
  continuationToken: string;
  totalRecords: number;
}

export interface EventBlock {
  budgets: Budget[];
  commentText: string;
  eventRequirements: EventRequirement[];
  sizeUnitOfMeasure: SizeUnitOfMeasure;
  startDates: StartDate[];
  totalEventBudget: number | null;
}

export interface EventRequirement {
  attendeeQuantity: number | null;
  commentText: string;
  dayNumber: number | null;
  endTime: string;
  eventSetupTypeName: string;
  eventTypeName: string;
  isPrivateRoom: boolean;
  isTwentyFourHourHold: boolean;
  roomSize: number | null;
  startTime: string;
}

export interface GuestRoomBlock {
  budgetedRoomRate: number | null;
  commentText: string;
  guestRoomRequirements: GuestRoomRequirement[];
  startDates: StartDate[];
}

export interface GuestRoomOccupancy {
  bedQuantity: number | null;
  name: string;
  occupancy: number | null;
  roomQuantity: number | 0;
}

export interface GuestRoomRequirement {
  commentText: string;
  dayNumber: number | null;
  guestRoomOccupancies: GuestRoomOccupancy[];
  roomTypeName: string;
}

export interface Location {
  id: string;
  name: string;
}

export interface Message {
  id: string;
  parentId: string | null;
  parentType: ResourceType;
  from: MessageCorrespondant;
  subject: string;
  date: string;
  meetingName: string;
}

export interface MessageCorrespondant {
  name: string;
  email: string;
}

export interface Organization {
  isPrimaryContact: boolean;
  organization: OrganizationDetail;
  organizationRole: string;
  person: Person;
}

export interface OrganizationDetail {
  id: string;
  addresses: Address[];
  commissions: Commission[];
  dunsNumber: string;
  externalId: string;
  iataNumber: string;
  name: string;
  people: Person[];
  phoneNumbers: PhoneNumber[];
  sicCode: string;
  url: string;
}

export interface Person {
  id: string;
  addresses: Address[];
  externalId: string;
  email: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  phoneNumbers: PhoneNumber[];
  title: string;
  titleSuffix: string;
}

export interface PhoneNumber {
  extension: string;
  name: string;
  number: string;
}

export interface Question {
  id: string;
  answer: Answer;
  category: string;
  isAnswerRequired: boolean;
  questionText: string;
  questionType: QuestionType;
  responseChoices: ResponseChoice[];
  responseUnits: ResponseUnit[];
  section: string;
}

export interface QuestionsAnswersReponseModel {
  data: QuestionsResponseModel;
}

export interface QuestionsResponseModel {
  questions: Question[];
}

export interface ResponseChoice {
  externalResponseChoiceId: string;
  value: string;
}

export interface ResponseUnit {
  externalResponseUnitId: string;
  unit: string;
}

export interface Meeting {
  id: string;
  attendeeQuantity: number | null;
  comments: Comment[];
  currency: string;
  currencyCode: string;
  leadSource: string;
  marketSegment: string;
  meetingName: string;
  meetingSeriesName: string;
  meetingType: string;
  numberOfAttendeesAtLargestEvent: number | null;
  numberOfEvents: number | null;
  numberOfNights: number | null;
  organizations: Organization[];
  peakRooms: number | null;
  promotionalCode: string;
  referenceNumber: string;
  respondByDate: string;
  responseInstructions: string;
  startDates: StartDate[];
  totalBudget: number | null;
}

export interface Address {
  cityRegion: string;
  countryCode: string;
  name: string;
  postalCode: string;
  stateProvince: string;
  streetAddress: string;
}

export interface Answer {
  responseText: string;
  responseUnits: string;
  responses: ResponseChoice[];
}

export interface Budget {
  name: string;
  value: number | null;
}

export interface Channel {
  id: string;
  name: string;
}

export interface Comment {
  name: string;
  text: string;
}

export interface Commission {
  name: string;
  revenueTypeName: string;
  value: number | null;
}

export enum OrganizationRole {
  Account,
  Agency
}

export enum ResourceType {
  RFP = 1,
  Meeting = 2,
  Proposal = 3
}

export enum QuestionType {
  Date = 1,
  Numeric,
  Text,
  SingleSelect,
  Multiselect
}

export enum SizeUnitOfMeasure {
  Feet,
  Meters
}

export interface RfpOrganization {
  organization: Organization;
  organizationRole: OrganizationRole;
  isPrimaryContact: boolean;
}

export interface Sender {
  location: Location;
  user: User;
}

export interface StartDate {
  name: string;
  value: string;
}

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  stateProvince: string;
  countryCode: string;
  cityRegion: string;
}

export interface RfpDetails {
  id: string;
  externalId: string;
  owner: User;
  channel: Channel;
  eventBlocks: EventBlock[];
  guestRoomBlocks: GuestRoomBlock[];
  meeting: Meeting;
  questions: Question[];
  releaseDate: string | null;
  status: string;
  sender: Sender;
  location: Location;
  messages: Message[];
  calculatedBudget: number | null;
}

export interface RFPStatusChange{
  Status:string;
  Comment:string;
  StartDate?: string;
  ReasonId:string;

}
export class RfpStatusConstants {

  static readonly newRfp = 'NewRfp';
  static readonly SelfAwarded = 'SelfAwarded';
  static readonly Reassign = 'Reassignment';
  static readonly TurnedDown = 'TurnedDown';
  static readonly Awarded = 'Awarded';
  static readonly Redirected = 'Redirected';
  static readonly Withdrawn = 'Withdrawn';
  static readonly Updated = 'Updated';
  static readonly Cancelled = 'Cancelled';
  static readonly Drafted = 'Drafted';
  static readonly Duplicate = 'Duplicate';
  static readonly Deleted = 'Duplicate';
  static readonly New = 'New';
}

export class RFPStatusChangeRequestModel {
  data?: RFPStatusChangeRequest;
}

export class RFPStatusChangeRequest {
  rfpStatusChange? : RFPStatusChange;
}

export class UpdateRfpOwnerChangeRequestModel {
  data?: UpdateRfpOwnerChangeRequest;
}

export class UpdateRfpOwnerChangeRequest {
  rfpOwnerChange? : User;
}

export interface PutReassignRfpRequestEnvelope {
  data?: RfpAssignment;
  errors?: ErrorObject;
  meta?: Meta;
}

export interface PutReassignRfpResponseEnvelope extends ApiResponse {
  data?: RfpAssignment;
  errors?: ErrorObject;
  meta?: Meta;
}

export class RfpAssignment {
  assignmentMethod: string;
  newUserId: string;
}
