<ng-select
    class="subscription-dropdown"
    data-test="cySubscriptionSelect"
    labelForId="subscriptionSelect"
    [selectOnTab]="false"
    name="subscriptionSelect"
    [items]="defaultBindingList"
    [(ngModel)]="selectedSubscription"
    [searchable]="true"
    [clearable]="false"
    [placeholder]= "'Select Subscription'"
    

    (ngModelChange)="onChange()"
    >
</ng-select>


<!-- TODO: we need to fix the pipe or use i18N or translate pipe -->
<!-- <ng-select
    class="subscription-dropdown"
    data-test="cySubscriptionSelect"
    labelForId="subscriptionSelect"
    [selectOnTab]="false"
    name="subscriptionSelect"
    [items]="defaultBindingList"
    [(ngModel)]="selectedSubscription"
    [searchable]="true"
    [clearable]="false"
    [placeholder]="'subscriptionlist-select-placeholder-text' | translate: 'Select Subscription'"
    

    (ngModelChange)="onChange()"
    >
</ng-select> -->
