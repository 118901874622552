import { ErrorObject } from "./error";
import { Meta } from "./meta";
import { RoleDefinition, UserInfo } from "./userInfo";

export interface SearchSubscriptionResponseModel {
    data?: ResponseData;
    errors?: ErrorObject[];
    correlationId: string;
    meta?: Meta;
}

export interface ResponseData {
    subscriptionSummaries: Subscription[];
}

export interface Subscription {
    id: string;
    name: string;
    status: string;
}

export interface SubscriptionContextContract extends UserInfo{
    selectedSubscription?: string;
    isMultiSubUser?: boolean;
}

/*
Using interface for SubContext does NOT restrict/limit properties to only the ones defined.
Interface/type definitions are lost when transpiled to js.
When saving the sub context to sessionStorage using the interface - all properties from the userinfo call were stored.
Keeping the interface definitions and using for api call responses, but using this class for persisting the usable context-
    to ensure that only the desired properties are saved to sessionStorage.

Ref: https://stackoverflow.com/questions/31829951/how-to-reduce-javascript-object-to-only-contain-properties-from-interface
*/
export class SubscriptionContext implements SubscriptionContextContract{
    /**
     * Flag to indicate if user has multiple subscriptions
     */
    public readonly isMultiSubUser?: boolean;
    
    /**
     * SubscriptionId for selected subscription
     */
    public readonly selectedSubscription?: string;

    /**
     * UserName of logged in user 
     */
    public readonly name: string;

    /**
     * UserId for logged in user (specific to selectedSubscription)
     */
    public readonly mbuserguid: string;

    /**
     * Role Definitions for logged in user (specific to selectedSubscription)
     */
    public readonly roles: RoleDefinition[];

    constructor(contract?: SubscriptionContextContract){
        this.isMultiSubUser = contract.isMultiSubUser;
        this.selectedSubscription = contract.selectedSubscription;
        this.name = contract.name;
        this.mbuserguid = contract.mbuserguid;
        this.roles = contract.roles;
    }
}