import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Features } from '../models/app-permission.model';
import { AppPermissionsService } from './app-permissions.service';
import { SharedService } from './shared.service';
import { DfSideNavItem, DfSideNavService } from '@design-factory/design-factory';

@Injectable({
  providedIn: 'root'
})
export class AppMenuService {
  public menusBehavior: BehaviorSubject<DfSideNavItem[]>;
  public menus: Observable<DfSideNavItem[]>;

  constructor(private sharedService: SharedService, private appPermissionService: AppPermissionsService, public readonly sideNavService: DfSideNavService,) {
    this.menusBehavior = new BehaviorSubject<DfSideNavItem[]>(this.getMenus());
    this.menus = this.menusBehavior.asObservable();
  }

  setMenus() {
    this.menusBehavior.next(this.getMenus())
  }

  getMenus() {
    let menus = [] as DfSideNavItem[]
    this.appPermissionService.getUserRoles().subscribe(userRole => {
      let userRoles = userRole.map(x => x.roleDefinition.roleName);

      const homeRoles = this.appPermissionService.getUserRolesByFeature(Features.Home);
      const rfpRoles = this.appPermissionService.getUserRolesByFeature(Features.RfpList);
      const messageRoles = this.appPermissionService.getUserRolesByFeature(Features.MessageList);

      if (userRoles && homeRoles.some(homeListRoles => userRoles.includes(homeListRoles))) {
        menus.push(
          {
            value: '/home',
            title: $localize`Home`,
            isActive: false,
            isCollapsed: false,
            type: 2,
          });
      }
      if (userRoles && messageRoles.some(messageListRoles => userRoles.includes(messageListRoles))) {
        menus.push(
          {
            value: '/messagelist',
            title: $localize`Messages`,
            isActive: false,
            isCollapsed: false,
            type: 2,
          });
      }
      if (userRoles && rfpRoles.some(rfpListRoles => userRoles.includes(rfpListRoles))) {
        menus.push(
          {
            value: '/rfplist',
            title: $localize`RFPs`,
            isActive: false,
            isCollapsed: false,
            type: 2,
          });
      }
    });
    return menus;
  }
}

