import { Component, OnInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { maxQtyValue, maxRateValue } from '../../constants/rfp-proposal-constants';

@Component({
  selector: 'app-grid-numeric-validator',
  templateUrl: './grid-numeric-validator.component.html',
  styleUrls: ['./grid-numeric-validator.component.scss']
})
export class GridNumericValidatorComponent implements ICellEditorAngularComp {
  inputValue: number;
  isValid: boolean = true;
  toolTipValue: string;
  params: any;
  elementId: string;
  rowIndex: number;

  constructor() { }

  agInit(params: ICellEditorParams<any, any>): void {
    this.params = params;
    this.inputValue = params.value;
    this.elementId = this.params.colDef.field;
    this.rowIndex = params.rowIndex;
  }
  
  getValue() {
    return this.inputValue;
  }

  ngOnInit(): void {
  }

  valueChanged() {
    this.elementId = this.params.colDef.field;

    if (this.params.colDef.headerName === "Qty") {
      if (!!(this.inputValue) || (this.inputValue < 0 || this.inputValue >= maxQtyValue) || (this.inputValue - Math.floor(this.inputValue)) !== 0) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      return this.inputValue;
    }
    if (this.params.colDef.headerName === "Rate") {
      if ((this.inputValue < 0 || this.inputValue >= maxRateValue)) {
        this.isValid = false;
      } else {
        this.isValid = true;
      }
      return this.inputValue;
    }
  }


}
