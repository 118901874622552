//TODO: AA Fix this service and why we are using it. 
//This will not work we need to fix it entire flow where we will read data from local storage and populate the translations 
// Or We may not need if we use localize we will have pre-compile version of languages 

import { Injectable } from '@angular/core';
import {LocalStorageConfigService} from '../services/local-storage-config.service';


export function setLanguageFactory(s:LocalStorageConfigService, applicationVersion: string = '0.0.0') {
  const lang = localStorage.getItem("en") || 'en';
  const version = applicationVersion; // During Local Development use Math.random() instead of applicationVersion, for translations to reflect correctly;
  return () => new Promise<any>((resolve, reject) => {
    const oReq = new XMLHttpRequest();
    oReq.addEventListener('load', (resp) => {
      if (oReq.status === 200) {
        try {
          const response = JSON.parse(oReq.responseText);
          if (version === s.localStorageDataVersion && lang === s.localStorageDataLanguage) {
            resolve(s.use());
          } else {
            const languageVersion = (version === '0.0.0') ? localStorage.getItem(s.localStorageVersionKeyName) : version;
            resolve(s.use({
              version: languageVersion,
              language: lang,
              //translations: response
            }));
          }
        } catch (e) {
          reject(e);
        }
      } else {
        console.error(`Language loading failed for ${lang}. Falling back to english`);
        oReq.open('GET', `../assets/i18n/en/en.json`);
        oReq.send();
      }
    });

    oReq.open('GET', `../assets/i18n/${lang}/${lang}.json`);
    oReq.send();

  });
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() { }

  getCurrentLanguage() {
    //TODO: AA find why we use it and fix it 
    return localStorage.getItem("en");
  }
}
