import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/helpers/configuration/app-config.service';

@Component({
  selector: 'app-privacy-terms',
  templateUrl: './privacy-terms.component.html',
  styleUrls: ['./privacy-terms.component.scss']
})
export class PrivacyTermsComponent implements OnInit {
  public policy_terms =  $localize `Use of this web site or of the Subscription Services offered on this web site shall constitute acceptance of Amadeus Hospitality's`;
  public privacy_policy = $localize `Privacy Policy`;
  public terms_of_use = $localize `Terms of Use`;
  public and = $localize `and`;
  constructor() { }

  ngOnInit(): void {
  }

}
