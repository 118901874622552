import { HelpConfig, PageHelpConfig } from "src/app/models/help-config";


export const pageConfigs: PageHelpConfig[] = [
    {
        pageId: 'RFPLIST',
        helpTopicId: "#rfps-list.html",
    },
    {
        pageId: 'RFPDETAILS.RFP.DETAILS',
        helpTopicId: "#rfp-details.html",
    },
    {
        pageId: 'RFPDETAILS.RFP.ATTACHMENTS',
        helpTopicId: "#rfp-attachments.html",
    },
    {
        pageId: 'RFPDETAILS.ADDITIONALINFO',
        helpTopicId: "#additional-info.html",
    },
    {
        pageId: 'RFPDETAILS.MESSAGES', // Legacy MB Tab = History / Transactions
        helpTopicId: "#rfp-messages.html",
    },
    {
        pageId: 'RFPDETAILS.BUSINESSDATA',
        helpTopicId: "#rfp-business-data-general.html",
    },
    {
        pageId: 'RFPDETAILS.OWNER_AND_TEAM',
        helpTopicId: "#owner-and-team.html",
    },
    {
        pageId: 'MESSAGELIST',
        helpTopicId: "#messages-list.html",
    },
    {
        pageId: 'MESSAGEDETAILS',
        helpTopicId: "#messages-details.html",
    },
];

export const helpConfig: HelpConfig = {
    baseHelpUrl: "https://help.amadeus-hospitality.com/group-and-event-distribution/meetingbroker/index.html",
    newBaseHelpUrl: "https://help-staging.amadeus-hospitality.com/meetingbroker/en-us/index.html",
    pageHelpConfigurations: pageConfigs
};
