import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, from, throwError } from "rxjs";
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { UserModel } from "../models/user";
import { Router } from "@angular/router";
import { Guid } from 'guid-typescript';
import { AppConfigService } from "../helpers/configuration/app-config.service";
import { SubscriptionContextContract } from "../models/subscription-list.model";
import { environment } from 'src/environments/environment';
import { User, UserManager, UserManagerSettings } from "oidc-client-ts";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;
  public root_Url;
  config;
  public isLoggedInSubject = new BehaviorSubject<boolean>(this.getAuthStatus());
  public isLoggedIn: Observable<boolean>;
  public userLoggedOut:boolean;

  amaCorrelationId: string;

  userManager: UserManager;
  user: UserModel;

  tokenExpirationInSeconds: number;
  changePasswordUrl: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private configService: AppConfigService
  ) {  
    this.isLoggedInSubject = new BehaviorSubject<boolean>(JSON.parse(sessionStorage.getItem('isLoggedIn')));
    this.isLoggedIn = this.isLoggedInSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject<UserModel>(
      JSON.parse(sessionStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.config = this.configService.getConfig();
    this.root_Url = this.config.authenticationUrl;

    let appConfigValues = JSON.parse(this.config.applicationConfigurations);
    this.tokenExpirationInSeconds =  appConfigValues['tokenExpirationSeconds']
    this.changePasswordUrl = appConfigValues['oidcAuthority'] + appConfigValues['PasswordResetUrl'];

    const settings: UserManagerSettings = {
      authority: appConfigValues['oidcAuthority'],
      client_id: this.config.clientId,
      redirect_uri: `${appConfigValues['clientRoot']}/login-callback`,
      silent_redirect_uri: `${appConfigValues['clientRoot']}/silent-callback.html`,
      post_logout_redirect_uri: `${appConfigValues['clientRoot']}/login`,
      response_type: 'code',
      scope: environment.clientScope,
      automaticSilentRenew: false,
      silentRequestTimeoutInSeconds: appConfigValues['silentRequestTimeoutInSeconds'],
      filterProtocolClaims: false
    };

    this.userManager = new UserManager(settings);
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  private updateAmaCorrelationId() {
    this.amaCorrelationId = Guid.create().toString();
  }

  public getUser(): Promise<User> {
    return this.userManager.getUser().then((user: User) => {
      if (user != null) {
        this.setCurrentUser(user);
      }
      return user;
    })
  }

  public renewToken(): Observable<User> {
    this.updateAmaCorrelationId();  // Create a new correlation id for the silent renew request
    return from(this.userManager.signinSilent({extraQueryParams: {"Ama-CorrelationId": this.amaCorrelationId}, extraTokenParams: {"Ama-CorrelationId": this.amaCorrelationId}} ));
  }

  public setCurrentUser(user: User){
    this.user = new UserModel();
    this.user.access_token = user.profile.legacy_token as string;
    this.user.refresh_token = user.profile.legacy_refresh_token as string;
    this.setExpiryDateToken();
    sessionStorage.setItem("currentUserToken", this.user.access_token);
    sessionStorage.setItem("refresh_token", this.user.refresh_token);
    sessionStorage.setItem("UserName", btoa(user.profile.name));
    sessionStorage.setItem("currentUser", JSON.stringify(user));
    sessionStorage.setItem('isTokenRefreshing', 'false');
    this.currentUserSubject.next(this.user);
  }

  login() {
    this.updateAmaCorrelationId();
    return this.userManager.signinRedirect({extraQueryParams: {"Ama-CorrelationId": this.amaCorrelationId}, extraTokenParams: {"Ama-CorrelationId": this.amaCorrelationId.toString()}});
  }
  
  // Because the expires_in value received from OIDC flow pertains to the jwt access token (not our legacy_access token which is currently being used).
  // We can no longer dynamically pass the expiration from the token response and must use fixed value.
  // Token expiration is now received from configuration.    
  setExpiryDateToken() {
    let expiryDate = Date.now() + (this.tokenExpirationInSeconds * 1000);
    sessionStorage.setItem("expiryDate", new Date(expiryDate).toString());
  }

  async logout() {  
    this.userLoggedOut = true;
    this.updateAmaCorrelationId();  
    sessionStorage.clear();
    this.isLoggedInSubject.next(null);
    this.currentUserSubject.next(null);
    await this.userManager.removeUser();
    return this.userManager.signoutRedirect({extraQueryParams: {"Ama-CorrelationId": this.amaCorrelationId}});
  }

  //In order to redirect and have the user change their password, we want to sign them out of the application.
  //This will force them to sign-back in using the new credentials
  async changePassword() {
    sessionStorage.clear();
    this.isLoggedInSubject.next(null);
    this.currentUserSubject.next(null);
    await this.userManager.removeUser();
    return location.href = this.changePasswordUrl;
  }

  redirectToErrorPage() {
    this.router.navigate(["/error"]);
  }

  getUserInfo() {
    let headers = new HttpHeaders();
    return this.httpClient.get<any>(`${this.root_Url}/ahws/v1/userinfo`, {
      headers: headers,
    });
  }

  getSubscriptionUserInfo(subscription: string) {
    let headers = new HttpHeaders();
    return this.httpClient.get<SubscriptionContextContract>(`${this.root_Url}/ahws/v1/userinfo/claims?subscriptionid=${subscription}`, {
      headers: headers,
    });
  }

  /**
   * return true, If user already loggedin 
   * @returns {boolean}
   */
  private getAuthStatus(): boolean {
    return sessionStorage.getItem('isLoggedIn') === 'true';
  }
}
