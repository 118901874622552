import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-checkbox-renderer',
  templateUrl: './grid-checkbox-renderer.component.html',
  styleUrls: ['./grid-checkbox-renderer.component.scss']
})
export class GridCheckboxRendererComponent implements ICellRendererAngularComp {
  params: any;

  constructor() { }
  agInit(params: any): void {
    this.params = params
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }
}
