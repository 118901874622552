import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Constants } from '../helpers/constants/constants';

export interface userSelectionData {
  filterData?: Map<string, string>,
  pageNumber?: number,
  pageSize?: number
}

export interface updateNotificationData {
  isUpdateOrCreate?: boolean,
  routeDataMessage?: SafeHtml
}

export interface continuationTokenList {
  tableName: string,
  pagenumber: number,
  continuationToken: string
}

@Injectable({
  providedIn: "root",
})
export class SharedService {
  applicationLanguage$ = new Subject<string>();
  continuationTokenList = new Array<continuationTokenList>();

  constructor() {}

  setContinuationToken(_pageNumber: number, _continuationToken: string, tableName: string, updateExistingToken: boolean = false) {
    if (_continuationToken) {
      tableName = this.getPaginationName(tableName);

      let tokenData: continuationTokenList = {
        tableName: tableName,
        pagenumber: _pageNumber,
        continuationToken: btoa(_continuationToken),
      };

      if (!updateExistingToken) {
        this.continuationTokenList.push(tokenData);
      } else {
        // update existing token
        let existingToken = this.continuationTokenList.find(
          (x) => x.pagenumber == _pageNumber && x.tableName == tableName
        );

        if (existingToken != null) {
          existingToken.continuationToken = btoa(_continuationToken);
        } else {
          this.continuationTokenList.push(tokenData);
        }
      }
    }
  }

  getContinuationToken(_pageNumber: number, tableName: string) {

    let tokenData = this.continuationTokenList.find(x => x.pagenumber == _pageNumber && x.tableName == this.getPaginationName(tableName));
    if(tokenData != null){
      return atob(tokenData.continuationToken);
    }

    return null;
  }

  clearAllContinuationTokens(tableName: string) {
    this.continuationTokenList = this.continuationTokenList.filter(
      (x) => x.tableName != this.getPaginationName(tableName)
    ); // this filter will remove all the tokens for specific table
  }

  getPaginationName(tableName: string) {
    return `${Constants.PAGINATIONTOKEN}-${tableName}`;
  }
}
