import { Component, OnInit, ViewChildren, ViewContainerRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { KeyCodes } from 'src/app/enums/keycodes.enum';

@Component({
  selector: 'app-grid-checkbox-editor',
  templateUrl: './grid-checkbox-editor.component.html',
  styleUrls: ['./grid-checkbox-editor.component.scss']
})
export class GridCheckboxEditorComponent implements ICellEditorAngularComp {
  public inputData: any;
  public params: any;
  isInputEnabled:boolean=true;
  rowIndex:number;
  @ViewChildren('input', { read: ViewContainerRef })

  public fieldName: any;
  // private focusedInput: number = 0;

  agInit(params: any): void {
    this.params = params
    this.fieldName = this.params.colDef.field;
    this.inputData = params.value;
    this.rowIndex=params.rowIndex;
  }

  getValue() {
    return this.inputData
  }


  public onChange(event) {
    this.inputData=event.currentTarget.checked;
    this.params.setValue(event.currentTarget.checked)
  }

}
