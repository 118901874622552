import { Guid } from "guid-typescript";
import { AttachmentSummary } from "projects/attachment-list/src/app/models/attachment-summary";
import { ErrorObject } from "src/app/models/error";

export interface MessageDetailsResponseModel {
  data: ResponseData;
  error: ErrorObject;
  correlationId: string;
}

export interface ResponseData {
  message: Message;
}

export enum MessageType {
  NewRFP = "NewRFP",
  UpdatedRFP = "UpdatedRFP",
  CcNewRfp = "CcNewRfp",
  RedirectedRFP = "RedirectedRFP",
  ReassignedRFP = "ReassignedRFP",
  ReassignedMeeting = "ReassignedMeeting",
  EscalatedRFP = "EscalatedRFP",
  CcEscalatedRfp = "CcEscalatedRfp",
  WithdrawnRFP = "WithdrawnRFP",
  CancelMeeting = "CancelMeeting",
  AwardRFP = "AwardRFP",
  NewProposal = "NewProposal",
  TurndownRFP = "TurndownRFP",
  UpdatedProposal = "UpdatedProposal",
  SelfAwardedProposal = "SelfAwardedProposal",
  CcRedirectedRfp = "CcRedirectedRfp",
  CcUpdatedRfp = "CcUpdatedRfp",
  CcNewProposal = "CcNewProposal",
  CcUpdatedProposal = "CcUpdatedProposal",
  SenderBusinessReminder = "SenderBusinessReminder",
  ReceiverBusinessReminder = "ReceiverBusinessReminder",
  TextMessage = "TextMessage",
  AddedToRfpTeam = "AddedToRfpTeam",
  RemovedFromRfpTeam = "RemovedFromRfpTeam",
  BusinessDataAdrValidation = "BusinessDataAdrValidation",
  RFPReleased = "RFPReleased"
}
export enum ParentType {
  Meeting = "Meeting",
  Proposal = "Proposal",
  RFP = "RFP",
}

export interface Message {
  id: Guid;
  parentId: Guid;
  parentType: string;
  messageType: string;
  body: string;
  contact: Contact;
  from: Correspondent;
  subject: string;
  to: Array<Correspondent>;
  date: Date;
  attachments?: AttachmentSummary[];
}

export interface Correspondent {
  name: string;
  email: string;
}

export interface Contact {
  organization: OrganizationSummary;
  person: PersonSummary;
}

export class PersonSummary {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  cityRegion: string;
  stateProvince: string;
  countryCode: string;
}

export interface OrganizationSummary {
  id: Guid;
  name: string;
}