import { Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { UserModel } from "../../models/user";
import { AuthenticationService } from "../../services/authentication.service";
import { AppComponent } from "../../app.component";
import { AppinsightsService } from "../../services/appinsights.service";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { ConfigManagerService } from "src/app/helpers/config-manager.service";
import { SafeHtml } from "@angular/platform-browser";
import { AppPermissionsService } from "src/app/services/app-permissions.service";
import { AppMenuService } from "src/app/services/app-menu.service";
import { SharedService } from "src/app/services/shared.service";
import { Features } from "src/app/models/app-permission.model";
//import { setLanguageFactory } from "src/app/services/language.service";
import { AppConfigService } from "src/app/helpers/configuration/app-config.service";
import { Constants } from "src/app/helpers/constants/constants";
import { SubscriptionListService } from "src/app/services/subscription-list.service";
import { SubscriptionContextContract } from "src/app/models/subscription-list.model";
import { User } from "oidc-client-ts";
import { LocalStorageConfigService } from '../../services/local-storage-config.service';
import { setLanguageFactory } from "src/app/services/language.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {

  user: UserModel;
  isLoader: boolean;
  private _success = new Subject<any>();
  isLoading: boolean = false;
  alertmessage: SafeHtml;
  alerttype: SafeHtml;
  unsubscribe$: Subject<void>;
  // Subscription Selection Vars
  isLoggedIn: boolean;
  subscriptionCount: number = 0;
  userInfo: SubscriptionContextContract;


  constructor(private router: Router, private authService: AuthenticationService, private app: AppComponent,
    private appInsightService: AppinsightsService,
    private configManagerService: ConfigManagerService, private appPermissionsService: AppPermissionsService,
    private menuService: AppMenuService, private sharedService: SharedService,
    private configService: AppConfigService, private subListService: SubscriptionListService, private localStorageConfig: LocalStorageConfigService) {
    // in case user clicks back button after hitting landing page
    this.app.sideNavService.hideSideNav();
    this.app.isSubscriptionSet = false; // Hide app header  
  }

  async ngOnInit() {
    this._success.subscribe(message => (this.alertmessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => (this.alertmessage = null));

    try {
      await this.authService.userManager.signinCallback();
    }
    catch (error) {
      this.isLoading = false;
      console.log(error);
      // This error is thrown when: 
      //      A) User clicks the back button on the initial landing page (navigating back from messages page to login page).
      //      B) User clicks the back button on the subscription page (navigating back to AHWS Login page --> reauthenticates and navigates back to this page).
      // The state is stored in storage after navigating to root URL (before AHWS login screen) and is removed from storage after calling signinCallback() in above try block.
      // Display appropriate error message to user and trigger a new login flow.
      if (error.message === "No matching state found in storage" && (this.authService.currentUserValue != null)) {
        this._success.next(Constants.NO_MATCHING_STATE);
        await this.redirectToLoginPageOnError();
        return;
      }
      else {
        // Not sure this block will ever get hit, but provide generic error message to user and trigger a new login flow just in case.
        this._success.next(Constants.UNEXPECTED_ERROR);
        await this.redirectToLoginPageOnError();
        return;
      }
    }
    this.unsubscribe$ = new Subject<void>();
    this.authService.isLoggedIn.pipe(takeUntil(this.unsubscribe$)).subscribe(val => { this.isLoggedIn = val });
    this.subListService.subscriptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(subscriptions => {
      this.subscriptionCount = subscriptions ? subscriptions.data.subscriptionSummaries.length : 0;
      if (this.subscriptionCount > 1) {
        this.isLoading = false;
        // return;
      }
      else if (this.subscriptionCount === 1) {
        // Subscription returned is same as initial context
        // Set initial contexts 'selectedSubscription' to Id from response
        this.userInfo.selectedSubscription = subscriptions.data.subscriptionSummaries[0].id;
        this.setSubscriptionContext();
      }
    });

    this.authoriseCredentials();
  }

  authoriseCredentials() {
    this.isLoading = true;
    this.authService.getUser().then((user: User) => {
      if (user != null) {
        this.app.user.firstName = atob(sessionStorage.getItem("UserName"));
        this.isLoading = true;
        this.setUserRoleAndLanguageFactory();
      }
    },
      (error) => {
        this.isLoading = false;
        this.app.isLoading = false;
        console.log("Login Unsuccessful");
      }
    );
  }

  setUserRoleAndLanguageFactory() {
    let secretValues = this.configManagerService.getSecrets();
    if (secretValues != null) {
      this.authService.getUserInfo().subscribe(
        (user) => {
          const userRoles = (<SubscriptionContextContract>user).roles.map((x) => x.roleDefinition.roleName);
          if (user.channel_internal_id > 0 || userRoles.length === 0) {
            this.redirectToLoginPageOnError();
            this._success.next(Constants.INVALID_LOGIN);
          }
          else if (userRoles.includes('Integration User')) {
            this.redirectToLoginPageOnError();
            this._success.next(Constants.LOGIN_NOT_ALLOWED);
          }
          else {
            this.userInfo = user;
            // At this point we don't know if user is a multi subscription user or not.
            // Set isMultiSubUser to false so that the auth-interceptor can wont add the subscription_guid header to the subscription api call.
            this.userInfo.isMultiSubUser = false;
            sessionStorage.setItem("mbUserGuid", this.userInfo.mbuserguid);
            this.subListService.setSelectedSubscription(this.userInfo); // Set initial subscription context with initial UserInfo response / default subscription info
            //TODO: AA Why we need it not used and should be removed
            setLanguageFactory(
              this.localStorageConfig,
              this.configService.getConfig().applicationVersion
            )
              ().then((_) => {
                this.sharedService.applicationLanguage$.next(
                  localStorage.getItem(
                    //this.translateService.localStorageLanguageKeyName
                    "en"
                  )
                );

                sessionStorage.setItem("isLoggedIn", "true");
                this.authService.isLoggedInSubject.next(true);
                this.initSubscriptions(user);
              });
          }
        },
        (error) => {
          this.redirectToLoginPageOnError();
          this._success.next(Constants.UNEXPECTED_ERROR);
        }
      );
    }
    this.appInsightService.logInfo(
      atob(sessionStorage.getItem("UserName")) +
      " has successfully logged in"
    );
  }

  initSubscriptions(user: SubscriptionContextContract) {
    this.subListService.searchSubscriptions();
  }

  onSubscriptionSelect($event: string) {
    this.isLoading = true;
    this.getSubscriptionContext($event);
  }

  getSubscriptionContext(subscription: string): void {
    this.authService.getSubscriptionUserInfo(subscription).subscribe((userInfo) => {
      // This is a multi subscription user. Set isMultiSubUser to true so that the auth-interceptor can add the subscription_guid header to all api calls.
      userInfo.isMultiSubUser = true;
      userInfo.selectedSubscription = subscription;
      this.userInfo = userInfo;
      this.setSubscriptionContext();
    },
      (error) => {
        this.redirectToLoginPageOnError();
        this._success.next(Constants.UNEXPECTED_ERROR);
      }
    );
  }

  setSubscriptionContext(): void {
    this.isLoader = true;
    this.subListService.setSelectedSubscription(this.userInfo); // set Subscription context / subscription specific userInfo
    this.appPermissionsService.setUserRoles(this.userInfo.roles); // use roles from subscription specific userInfo
    this.menuService.setMenus();
    this.subListService.setSubscriptionStatus(true); // enable app.component to render
    this.redirect();
    this.isLoading = false;
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  redirect() {
    this.appPermissionsService.getUserRoles().subscribe((roles) => {
      // user roles that come back from userinfo call
      const userRoles = roles.map((x) => x.roleDefinition.roleName);
      // required roles to access feature pages   
      const homeRoles = this.appPermissionsService.getUserRolesByFeature(Features.Home);
      const messageRoles = this.appPermissionsService.getUserRolesByFeature(Features.MessageList);
      const rfpRoles = this.appPermissionsService.getUserRolesByFeature(Features.RfpList);

      //redirect to email link address if user has populated redirectUrl
      if (this.redirectToEmailLinkAddress()) {
        return;
      }

      let destination: string = "";

      // Default to Home / Dashboard if user has access to it - otherwise check other pages.
      if (userRoles && homeRoles.some((homeListRole) => userRoles.includes(homeListRole))) {
        destination = "/home";
      }
      else if (userRoles && messageRoles.some((messageListRole) => userRoles.includes(messageListRole))) {
        destination = "/messagelist";
      }
      else if (userRoles && rfpRoles.some((rfpListRole) => userRoles.includes(rfpListRole))) {
        destination = "/rfplist";
      }
      else {
        destination = "/under-progress";
      }
      this.appInsightService.logInfo(`Redirecting to ${destination} for subscription ${this.userInfo.selectedSubscription}.`);
      this.router.navigate([destination]);
    });
  }

  redirectToEmailLinkAddress(): boolean {
    let redirectUrl = sessionStorage.getItem("redirectUrl");
    if (redirectUrl) {
      sessionStorage.removeItem("redirectUrl");
      //navigateByUrl maintains the querystring parameters if any
      this.router.navigateByUrl(redirectUrl);
      return true;
    }
    return false
  }

  async redirectToLoginPageOnError() {
    this.alerttype = "danger";
    this.isLoading = false;
    document.querySelector("body").style.backgroundColor = "#f2f2f2";
    await new Promise(resolve => setTimeout(resolve, 5000)); // keep error message displayed for 5 seconds
    this.subscriptionCount = 0;
    this.isLoggedIn = false;
    await this.authService.logout();
  }
}
