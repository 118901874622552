import { Component, OnInit, QueryList, ViewChildren, ViewContainerRef, } from "@angular/core";
import { ICellEditorAngularComp } from "ag-grid-angular";
import moment from "moment";
import { DateService } from "src/app/services/date.service";
import { LocaleService } from "src/app/services/locale.service";
import { MAX_NO_OF_GUESTROOM_DAYS } from "../../constants/rfp-proposal-constants";

@Component({
  selector: "app-grid-date-cell-editor",
  templateUrl: "./grid-date-cell-editor.component.html",
  styleUrls: ["./grid-date-cell-editor.component.scss"],
})
export class GridDateCellEditorComponent implements ICellEditorAngularComp {
  public inputDate: any;
  public previousDate: any;
  public params: any;
  public minDateValue: any;
  public maxDateValue: any;
  public instanceId: any;
  isValid: boolean = true;
  validatedValues: any = {};

  @ViewChildren("input", { read: ViewContainerRef })
  public inputs: QueryList<any>;
  public fieldName: any;
  placeholderFormat = {
    de: "DD.MM.YYYY",
    fr: "DD/MM/YYYY",
    es: "DD/MM/YYYY",
    en: "MM/DD/YYYY",
  };
  placeHolder: any;
  public filterProposalGuestroomAllDaysInfo: any;

  constructor(private localeService: LocaleService,
    private readonly dateService: DateService) { }

  agInit(params: any): void {
    this.params = params;
    this.instanceId = this.params.column.instanceId;
    this.fieldName = this.params.colDef.field;
    this.inputDate = moment(params.value, this.placeholderFormat[this.localeService.locale]).format('YYYY-MM-DD');
    this.previousDate = this.inputDate;

  }

  ngOnInit(): void {
    this.params.data["isValid"] = true;
    this.minDateValue = this.dateService.getProposalEBMinDate();
    this.maxDateValue = moment(this.minDateValue, "YYYY-MM-DD").add(MAX_NO_OF_GUESTROOM_DAYS-2, "days").format("YYYY-MM-DD");
    this.placeHolder = this.placeholderFormat[this.localeService.locale];
  }

  getValue() {
    let regexForYYYYMMDD = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;    //YYYY-MM-DD
    return moment(this.inputDate ? this.inputDate : "", regexForYYYYMMDD.test(this.inputDate ? this.inputDate : this.previousDate) ? 'YYYY-MM-DD' : this.placeholderFormat[this.localeService.locale]).format(this.placeholderFormat[this.localeService.locale]);
  }

  valueChanged() {
    if(this.inputDate){
      this.isValid = true;
    }else{
      this.isValid = false;
    }
  }

  disableDate(e: any) {
    if (e.which != 13 && e.which != 27 && e.which != 9){  // This function disables the user from manually entering values. We are allowing only Enter keypress to bypass this function. The ASCII code for Enter key is 13.
      return false;
    }
  }

}
