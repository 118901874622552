import { Injectable, Pipe } from '@angular/core';
import { LocaleService } from './locale.service';
import * as moment from 'moment-timezone';
import { DatePipe } from '@angular/common';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ProposalGuestroomDayInfoViewModel } from 'projects/rfp-list/src/app/models/proposal-details-guestroom-info-view-model';

@Injectable()
@Pipe({
  name: 'date-utc'
})
export class DateService {

  public startDate: any;
  public endDate: any;
  public previousDate:any;
  private maxdaysspan: number;
  public currentValueEquals: string;
  public operator: string;
  public minDate: string;
  public proposalEBMinDate:string;
  public isValidDate:boolean=true;
  public proposalGuestroomAllDaysInfo: ProposalGuestroomDayInfoViewModel[] = [];


  constructor(private readonly localeService: LocaleService, public datepipe: DatePipe) {
    if (sessionStorage.getItem('MaxDateSpanForSearch') !== "undefined") {
      this.maxdaysspan = +sessionStorage.getItem('MaxDateSpanForSearch');
    } else {
      this.maxdaysspan = 180;
    }
  }


  setPreviousDate(val:any){
    this.previousDate = val;
  }
  
  getPreviousDate(){
    return this.previousDate;
  }

  setProposalEBMinDate(ebMinDateValue){
    this.proposalEBMinDate = ebMinDateValue;
  }

  getProposalEBMinDate(){
    return this.proposalEBMinDate;
  }

  setCurrentValue(startdate, enddate) {
    if (!startdate && !enddate) {
      this.startDate = "";
      this.endDate = "";
      this.currentValueEquals = "";
    } else {
      this.currentValueEquals = "";
      this.startDate = moment(startdate).format("DD-MMMM-YYYY");
      this.endDate = moment(enddate).format("DD-MMMM-YYYY");
    }

  }

  setCurrentValueEquals(data) {
    if (data) {
      this.startDate = "";
      this.endDate = "";
      this.currentValueEquals = moment(data).format("DD-MMMM-YYYY")
    }
    else {
      this.startDate = "";
      this.endDate = "";
      this.currentValueEquals = "";
    }
  }

  clearValues(){
    this.currentValueEquals="";
    this.startDate="";
    this.endDate="";
  }

  setMinMonth(data) {
    if (data) {
      this.minDate = moment.tz(data, this.localeService.timezone).utc().subtract(this.maxdaysspan - 1, "days").format("YYYY-MM-DD");
    } else {
      this.minDate = "1000-01-01";
    }
  }

  setProposalGuestroomDates(existingDates:any){
    this.proposalGuestroomAllDaysInfo = existingDates;
  }

  getProposalGuestroomDates(){
    return this.proposalGuestroomAllDaysInfo;
  }

  public convertDateStringToNgbDate(obj: any, format: string): NgbDate{
    if(obj){
      let regexForYYYYMMDD1 = /^\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12]\d|3[01])$/;    //YYYY-MM-DD
      let regexForYYYYMMDD2 = /^\d{4}\/(?:0[1-9]|1[0-2])\/(?:0[1-9]|[12]\d|3[01])$/;  //YYYY/MM/DD

      switch(format){
        case "YYYY-MM-DD" :
          if(regexForYYYYMMDD1.test(obj)){
            const [year, month, day] = obj.split('-').map(Number);
            return new NgbDate(year, month, day);
          }
        case "YYYY/MM/DD" :
          if(regexForYYYYMMDD2.test(obj)){
            const [year, month, day] = obj.split('/').map(Number);
            return new NgbDate(year, month, day);
          }
        default: return null;
      }
    }
  }

  setIsValidProposalGuestroomDate(valid:boolean){
    this.isValidDate = valid;
  }

  getIsValidProposalGuestroomDate(){
    return this.isValidDate;
  }
}
