export const dateTimeFormatOptionValues: Intl.DateTimeFormatOptions = {
    hour12: true,
    hourCycle: "h12",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "numeric",
    second: "numeric",
  };

export const dateTimeFormatOptionsForDateOnly: Intl.DateTimeFormatOptions  = {
  hour12: true,
  hourCycle: "h12",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

export const shortDateTimeFormatOptionsForDateOnlyLocalized: Intl.DateTimeFormatOptions  = {
  hour12: true,
  hourCycle: "h12",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  weekday: 'short'
};

export const dateTimeFormatOptionsForDateOnlyLocalized: Intl.DateTimeFormatOptions  = {
  hour12: true,
  hourCycle: "h12",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  weekday: 'long'
};

export const dateTimeFormatOptionsForTimeOnly : Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit"
};

export const dateTimeFormatOptionValuesWithWeekDay: Intl.DateTimeFormatOptions = {
  weekday: "long",
  hour12: true,
  hourCycle: "h12",
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};