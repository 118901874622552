import { Component, OnInit } from "@angular/core";
import { User } from "oidc-client-ts";
import { AuthenticationService } from "src/app/services/authentication.service";

@Component({
  selector: "app-oidc-login",
  templateUrl: "./oidc-login.component.html",
  styleUrls: ["./oidc-login.component.scss"],
})
export class OidcLoginComponent implements OnInit {

  currentUser: User

  constructor(private authService: AuthenticationService) {  }

  async ngOnInit() {
    this.authService.login();
  };
  
}
