export interface ErrorObject {
    status: number;
    code: number;
    title: string;
    detail: string;
    source?: ErrorSource;
}

export interface ErrorSource {
    parameter: string;
    pointer: string;
    example: string;
}

export const ErrorCode = {
    BAD_REQUEST: 4926,
    INTERNAL_SERVER_ERROR: 141,
    SERVICE_UNAVAILABLE: 974
}

export const ERRORCODESTOLOG = [400, 403, 404, 409, 410, 413, 422]