import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { SubscriptionListService } from 'src/app/services/subscription-list.service';
import { takeUntil } from "rxjs/operators";
import { Subject } from 'rxjs';
import { ValueLabel } from 'src/app/helpers/acl-grid/valueLabel';


@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Disable Encapsulation so that component style can be applied to the ng-select element's 'ng-dropdown-panel'.
  // This is the only way I've been able to successfully limit the visible rows in dropdown to 5 items
  encapsulation: ViewEncapsulation.None,
})
export class SubscriptionListComponent implements OnInit, OnDestroy {

  @Output() subscriptionSelectedEvent: EventEmitter<string> = new EventEmitter();

  unSubscribe$ = new Subject();
  defaultBindingList: ValueLabel[];
  selectedSubscription: ValueLabel;

  constructor(private _subscriptionService: SubscriptionListService) { }

  ngOnInit(): void {
    this._subscriptionService.subscriptions$.pipe(takeUntil(this.unSubscribe$)).subscribe(response => {
      this.defaultBindingList = new Array<ValueLabel>();
      response.data.subscriptionSummaries.forEach(sub => this.defaultBindingList.push({
        value: sub.id,
        label: sub.name
      }));
    });
  }

  onChange() {
    this.subscriptionSelectedEvent.emit(`${this.selectedSubscription.value}`)
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

}
