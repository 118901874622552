import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  pageNotFoundHeader: string;
  pageNotFoundMessage: string;

  constructor() { }

  ngOnInit() {
    this.pageNotFoundHeader = $localize `Page Not Found`;
    this.pageNotFoundMessage = $localize `We couldn\'t find that page`;
  }

}
