import { Injectable } from "@angular/core";
import { DfModalService } from "@design-factory/design-factory";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AlertInfoComponent } from "../components/alert-popup/alert-info.component";
import { toastTypes } from "../utils/constants";

export interface AlertPopupConfig {
  popupConfig: {
    scrollable: boolean,
    size: string,
    backdrop: boolean | 'static',
    centered: boolean
  },
  data: {
    title: string,
    message: string,
    okButtonText: string,
    cancelButtonText: string,
    type: string,
    isHeaderRequired: boolean,
    isCancelButtonEnabled: boolean
    isCancelButtonVisible: boolean
    isFooterVisible: boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class AlertPopupService {

  constructor(private readonly modalService: DfModalService) { }

  showPopup(config: AlertPopupConfig) {

    if (!config || !config.data) return;

    let modalRef: NgbModalRef;

    modalRef = this.modalService.open(AlertInfoComponent, {
      ariaLabelledBy: "modal-title-id",
      scrollable: config.popupConfig?.scrollable ?? true,
      size: config.popupConfig?.size ?? 'md',
      backdrop: config.popupConfig?.backdrop ?? 'static',
      centered: config.popupConfig?.centered ?? true,
    });

    modalRef.componentInstance.type = config.data.type ?? toastTypes.info; // info, error, warning
    modalRef.componentInstance.isHeaderRequired = config.data.isHeaderRequired;
    modalRef.componentInstance.title = this.setTitle(config);
    modalRef.componentInstance.message = config.data.message ? $localize `${config.data.message}` : '';
    modalRef.componentInstance.okButtonText = config.data.okButtonText ? $localize `${config.data.okButtonText}` : $localize `OK`;
    modalRef.componentInstance.cancelButtonText = config.data.cancelButtonText ? $localize `${config.data.cancelButtonText}` : $localize `Cancel`;
    modalRef.componentInstance.isCancelButtonEnabled = config.data.isCancelButtonEnabled ?? true;
    modalRef.componentInstance.isCancelButtonVisible = config.data.isCancelButtonVisible ?? true;
    modalRef.componentInstance.isFooterVisible = config.data.isFooterVisible ?? true;

    return modalRef.result;
  }

  private setTitle(config: AlertPopupConfig): string {
    if (!config.data.isHeaderRequired) return null;
    const text = config.data.type;

    return config.data.title ? $localize `${config.data.title}` : (text.charAt(0).toUpperCase() + text.slice(1))
  }

}
