import { Injectable } from '@angular/core';


export interface TranslationServiceConfig {
    version: string;
    language?: string;
    consoleLog?: boolean;
}

const localStoragePrefix = 'mblocalStorage';

export const TranslationServiceLocalStorageConfig = {
    data: `${localStoragePrefix}Data`,
    dataLanguage: `${localStoragePrefix}DataLanguage`,
    dataVersion: `${localStoragePrefix}DataVersion`,
    loadedModules: `${localStoragePrefix}LoadedModules`,
    version: `${localStoragePrefix}Version`,
    language: `${localStoragePrefix}Language`
};

const localStorageConfig = TranslationServiceLocalStorageConfig;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageConfigService {

  private _lang: string;
  private _version: string;  
  private _consoleLog = false;
  private _localStorageDataKeyName = localStorageConfig.data;
  private _localStorageDataLanguageKeyName = localStorageConfig.dataLanguage;
  private _localStorageDataVersionKeyName = localStorageConfig.dataVersion;
  private _localStorageLoadedModulesKeyName = localStorageConfig.loadedModules;
  private _localStorageVersionKeyName = localStorageConfig.version;
  private _localStorageLanguageKeyName = localStorageConfig.language;
  private readonly variableSyntaxRegEx = new RegExp(/\$\{\s?([^{}\s]*)\s?\}/, 'g');

  constructor() { }

  get lang(): string {
    return this._lang;
  }

  get localStorageVersionKeyName(): string {
    return this._localStorageVersionKeyName;
  }

  get localStorageLanguageKeyName(): string {
    return this._localStorageLanguageKeyName;
  }

  get localStorageDataVersion(): string {
    return localStorage.getItem(this._localStorageDataVersionKeyName);
  }

  get localStorageDataLanguage(): string {
    return localStorage.getItem(this._localStorageDataLanguageKeyName);
  }

  get version(): string {
    if (!!this._version) {
      return this._version;
    } else {
      this._version = localStorage.getItem(this._localStorageVersionKeyName);
      return this._version;
    }
  }

  use(config?: TranslationServiceConfig) {
    if (!!config) {
      this._consoleLog = config.consoleLog;
    }
    if (
      !!config &&
      (
        config.version !== this.version ||
        config.language !== this.lang ||
        !this.hasData()
      )
    ) {
      this._lang = config.language;
      this._version = config.version;
      localStorage.setItem(this._localStorageLanguageKeyName, config.language);
      localStorage.setItem(this._localStorageVersionKeyName, config.version);
      //this.loadTranslations(config.translations, 'root');
    } else {

      this.loadFromLocalStorage();

      if (!!this._lang) {
        localStorage.setItem(this._localStorageLanguageKeyName, this._lang);
      }
      if (!!this._version) {
        localStorage.setItem(this._localStorageVersionKeyName, this._version);
      }
    }
  }


  hasData() {
    // if (!this.data) {
    //   this.data = JSON.parse(localStorage.getItem(this._localStorageDataKeyName));
    //   return !!this.data;
    // } else {
    //   return !!this.data;
    // }

    return true;
  }

  
  loadFromLocalStorage() {
    this._lang = localStorage.getItem(this._localStorageDataLanguageKeyName);
    this._version = localStorage.getItem(this._localStorageDataVersionKeyName);
    //this.data = JSON.parse(localStorage.getItem(this._localStorageDataKeyName));
    // this.lazyLoadedModules = JSON.parse(
    //   localStorage.getItem(this._localStorageLoadedModulesKeyName)
    // );
  }

  setLanguage(lang: string) {
    this._lang = lang;
    localStorage.setItem(this._localStorageLanguageKeyName, lang);
  }

}
