//TODO: need to rewrite if we need to use this pipe
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor() { }

  transform(value: string, defaultText: string): string {
    return  $localize `${defaultText}`;
  }

}
