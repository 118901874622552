import { ApiResponse, ErrorObject } from "projects/rfp-list/src/app/models/api-response.model";
import { MbRole } from "src/app/models/mb-role"
import { Meta } from "@angular/platform-browser";
import { Guid } from "guid-typescript";
import { RFPStatusChange, RFPStatusChangeRequest } from "projects/rfp-list/src/app/models/rfp-detail.model";
import { MessageType,Correspondent,ParentType } from "projects/message/src/app/models/message-details.model";

export interface CreateMessageInfo {
    MessageFlow : CreateMessageFlow,
    CommonInfo : CreateMessageCommonInfo,
    ProposalInfo?  : ProposalInfo,
    TurnDownInfo?: TurnDownInfo,
    UserRole : MbRole,
    SelfAwardInfo?: SelfAwardInfo
}

export interface TurnDownInfo{
    SystemRecipients: string,
    TurnDownReasons: string[]
}

export class TurnDownRequestModel {
    data?: RFPStatusChangeRequest;
  }


export interface PutStatusResponseEnvelope extends ApiResponse {
    data?: PutStatusResponse;
    errors?: ErrorObject;
    meta?: Meta;
  }

  export interface PutStatusResponse {
    reference: ResourceObject;
  }

  export interface ResourceObject
  {
    resourceType: string;
    id: Guid;
    //associations: ResourceObject[];
  }

export interface CreateMessageCommonInfo {
    To : string,
    ToEmail? : string,
    From : string,
    Subject : string,
    Organization : string,
    PrimaryContact : string,
    Comments? : string,
    RfpId: Guid,
    ProposalId?:Guid,
    Person?:Person,
    ProposalLastSentDate?:string,
    ToId?:Guid,
    LocationName?:string
}

export interface ProposalInfo {
    Cc : string,
    AllowResponseInstructionOverride : boolean,
    ResponseInstructionType : ResponseInstructionType,
    Date : string,
    RichTextEditorData : any
}

export enum CreateMessageFlow {
    NewRfp = 'NewRfp',
    SelfAward = 'Self Award',
    TurnDown = 'Turn Down',
    Reassign = 'Reassign',
    Redirect = 'Redirect',
    Proposal = 'Proposal',
    UpdateRfp = 'UpdateRfp'
}


export interface CreateMessageFormModel {
    To : string,
    ToEmail? : string,
    Cc? : string,
    RichText? : any,
    Comments? : string,
    Attachments : Attachment[],
    SelfAwardDate? : string,
    flow: CreateMessageFlow,
    systemRecipients?: string,
    turnDownReason?: string,
    rfpId?: Guid,
    Subject?:string,
    From?:string,
    proposalId?:Guid,
    proposalDate?:string
    Person?:Person
    Organization?:string,
    ProposalLastSentDate?:string,
    LocationName?:string
}

export interface UiFieldReadonlyStates {
    To: boolean,
    Cc?: boolean,
    ToEmail?:boolean,
    Comment?: boolean
}

export interface SelfAwardInfo {
    Date : string,
}

export enum ResponseInstructionType
{
    None = -1,
    RespondToSender = 1,
    RespondToPrimaryContact = 2,
    RespondToBoth = 3
}

export interface MessageModel {
  ParentId: string;
  ParentType: ParentType;
  MessageType: MessageType;
  Attachments?: Attachment[];
  Body?: string;
  Contact?: Contact;
  From?: Correspondent;
  To?: Correspondent;
  Subject?: string;
  Date: string;
  CcRecipientEmailIdList:string
}

export interface MessageRequestModel
{
  data : MessageRequest;
}

export interface MessageRequest
{
  message : Message;
}

export interface Message {
  ParentId: string;
  ParentType: ParentType;
  MessageType: MessageType;
  Attachments?: Attachment[];
  Body?: string;
  Contact?: Contact;
  From?: Correspondent;
  To?: Array<Correspondent>;
  Subject?: string;
  Date: string;
  Cc?: Array<Correspondent>;
}

export interface Person{
  email: string,
  firstName: string,
  lastName: string,
};
export interface Contact {
  organization: Organization;
  person: Person;
}
export interface Organization {
  id: Guid;
  name: string;
}
export interface Attachment{
  id:string;
}