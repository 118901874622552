import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageAttachmentsPath, MessageListSearchServiceUrlPath } from '../utils/constants';
import { Observable } from 'rxjs/internal/Observable';
import { GetAttachmentStatusResponseModel } from '../models/attachment.model';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class AttachmentService {


  private messageServiceUrl: string;

  constructor(private http: HttpClient) {
    this.messageServiceUrl = `${sessionStorage.getItem("MessageServiceBaseUrl")}${MessageAttachmentsPath}`;
    
  }
  public getAttachmentStatusDetails(attachmentId: string): Observable<GetAttachmentStatusResponseModel> {
    return this.http.get<GetAttachmentStatusResponseModel>(`${this.messageServiceUrl}/${attachmentId}/status`);
  }
}
