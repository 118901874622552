import { Injectable, OnDestroy } from '@angular/core'; import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CreateMessageFlow, CreateMessageFormModel, CreateMessageInfo, Message, MessageRequest, MessageRequestModel, PutStatusResponseEnvelope, TurnDownRequestModel } from '../models/create-message.model';
import { Exception } from '@microsoft/applicationinsights-web';
import { Guid } from 'guid-typescript';
import { SearchRFPServiceUrlPath, MessageListSearchServiceUrlPath, MessageAttachmentsPath } from "src/app/utils/constants";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RFPError } from 'projects/rfp-list/src/app/constants/rfp-error.constants';
import { ActivatedRoute } from '@angular/router';
import { RFPStatusChange, RFPStatusChangeRequest } from 'projects/rfp-list/src/app/models/rfp-detail.model';
import { MessageModel} from 'src/app/models/create-message.model';
import { Correspondent, MessageType, ParentType } from "projects/message/src/app/models/message-details.model";
import moment from 'moment';
import { FileUploadModel } from '../models/file-upload.model';

@Injectable({
  providedIn: "root",
})
export class CreateMessageService implements OnDestroy {
  private rfpServiceUrl: string;
  private messageServiceUrl: string;
  private messageAttachmentUrl: string;
  private createMessagePageDataSubject$;
  private rteDataSubject$ = new Subject<any>();
  errors$ = new Subject<any>();
  rfpId: Guid;
  unSubscribe$ = new Subject();
  parentId: Guid;

  constructor(private http: HttpClient) {
    this.rfpServiceUrl = `${sessionStorage.getItem("RFPServiceBaseUrl")}${SearchRFPServiceUrlPath}`;
    this.messageServiceUrl = `${sessionStorage.getItem("MessageServiceBaseUrl")}${MessageListSearchServiceUrlPath}`;
    this.messageAttachmentUrl = `${sessionStorage.getItem("MessageServiceBaseUrl")}${MessageAttachmentsPath}`;
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  createMessagePageLoad(createMessageInfo: CreateMessageInfo) {
    this.createMessagePageDataSubject$ =new BehaviorSubject<CreateMessageInfo | null>(null);
    this.createMessagePageDataSubject$.next(createMessageInfo);
  }

  getCreateMessageData(): CreateMessageInfo {
    try {
      return this.createMessagePageDataSubject$.value;
    } catch (exception) {
      console.log(exception);
      return null;
    }
  }

  async CreateMessage(formData: CreateMessageFormModel) {
    let requestBody = this.getCreateMessageRequestBody(formData);
    try {
      let response = await this.createMessageService(requestBody);
      return response;
    } catch(err) {
      console.log("Error: " + err.error.errors);
      this.handleServiceError(err);
    }
    
  }

  getCreateMessageRequestBody(formData: CreateMessageFormModel) {
    let parentType: ParentType;
    let parentId :Guid;
    let date = new Date(Date.now()).toDateString();
    let messageType: MessageType;

    switch (formData.flow) {
      case CreateMessageFlow.Proposal:{
        parentType = ParentType.Proposal;
        parentId = formData.proposalId;
        messageType = formData.ProposalLastSentDate != ""  ? MessageType.UpdatedProposal : MessageType.NewProposal ;
        break;
      }
      case CreateMessageFlow.NewRfp:{
        parentType = ParentType.RFP;
        parentId = formData.rfpId;
        messageType = MessageType.NewRFP
        break;
    }
      case CreateMessageFlow.SelfAward:{
        parentType = ParentType.RFP;
        parentId = formData.rfpId;
        messageType = MessageType.SelfAwardedProposal
        break;
    }
      case CreateMessageFlow.UpdateRfp:{
        parentType = ParentType.RFP;
        parentId = formData.rfpId;
        messageType = MessageType.UpdatedRFP
        break;
    }
      case CreateMessageFlow.TurnDown:{
        parentType = ParentType.RFP;
        parentId = formData.rfpId;
        messageType = MessageType.TurndownRFP
        break;
    }
    case CreateMessageFlow.Reassign:{
      parentType = ParentType.RFP;
      parentId = formData.rfpId;
      messageType = MessageType.ReassignedRFP
      break;
  }
      default:
        // TODO: we will show some error if nothing matched
        break;
    }

    let toFieldData: Array<Correspondent> = new Array<Correspondent>();
    if(formData.To != null && formData.To != undefined)
    {
      formData.To.split(";").forEach((x)=>{
       toFieldData.push({ email: x, name: x});
      });
    }
    let toEmailFieldData: Array<Correspondent> = new Array<Correspondent>();
    if(formData.ToEmail != null && formData.ToEmail != undefined)
    {
      formData.ToEmail.split(";").forEach((x)=>{
        toEmailFieldData.push({ email: x, name: x});
      });
      toFieldData.forEach((x,i)=>{toEmailFieldData[i].name = x.name.replace(' at '+formData.LocationName,'')});
    }

    let ccFieldData: Array<Correspondent> = new Array<Correspondent>();
    if(formData.Cc != null && formData.Cc != undefined)
    {
      formData.Cc.split(";").forEach((x)=>{
        ccFieldData.push({ email: x, name: x});
      });
    }   
  let isProposalFlow = [MessageType.NewProposal, MessageType.UpdatedProposal, MessageType.CcNewProposal, MessageType.CcUpdatedProposal].includes(messageType);
  let messageModel: Message = {
    Attachments: formData.Attachments,
    Body: isProposalFlow ? formData.RichText : formData.Comments,
    Date: this.formatDateToUTC(
      formData.flow == CreateMessageFlow.SelfAward
        ? formData.SelfAwardDate
        : date
    ),
    Contact: {
      organization: {
        id: null,
        name: formData.Organization,
      },
      person: {
        email: formData.Person.email,
        firstName: formData.Person.firstName,
        lastName: formData.Person.lastName,
      },
    },
    From: {
      email: "",
      name: formData.flow == CreateMessageFlow.Reassign? formData.From.replace(' at '+formData.LocationName,'') : formData.From,
    },
    To: formData.flow == CreateMessageFlow.Reassign? toEmailFieldData : toFieldData,
    MessageType: messageType,
    ParentType: parentType,
    ParentId: parentId.toString(),
    Subject: formData.Subject,
    Cc : ccFieldData
  };

  let messageRequestModelBase: MessageRequestModel= { 
    data: { 
      message: messageModel
  }};

  return messageRequestModelBase;
} 
 
  createMessageService(request: MessageRequestModel) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post(this.messageServiceUrl, request, {
        headers: headers,
        observe: 'response' 
      })
      .toPromise();
  }

  formatDateToUTC(date: string | Date) {
    if (date != "") {
      return moment(date).format("YYYY-MM-DD").toString();
    }
    return "";
  }

  handleServiceError(err: any): void {
    const errors = err?.error?.errors?.filter((x) => x.code);
    if (!errors || !errors.length) return;

    errors.forEach((e) => {
      switch (e.code) {
        case RFPError.BAD_REQUEST:
          this.errors$.next({
            translateKey: "rfplist-messages-txt-badrequestmessage",
            defaultText: "Oops, something went wrong. Please check your input.",
          });
          break;
        case RFPError.INTERNAL_SERVER_ERROR:
          this.errors$.next({
            translateKey: "common-messages-txt-internalservererror",
            defaultText: "Something went wrong. Please try again later.",
          });
          break;
        default:
          this.errors$.next({
            translateKey: "common-messages-txt-internalservererror",
            defaultText: "Something went wrong. Please try again later.",
          });
      }
    });
  }
  sendRTEData(value: any) {
    //TODO: remove the logs added for QA testing.
    console.log(value);
    this.rteDataSubject$.next(value);
  }

  getRteData() {
    return this.rteDataSubject$.asObservable();
  }

  createAttachment(data: FileUploadModel){
    let promise = new Promise((resolve, reject) => {
    let headers = new HttpHeaders().set("Content-Type", "application/json");
    const requestBody = {
      "data": data
    };
    
    this.http
      .post(this.messageAttachmentUrl, requestBody, {
        headers: headers
      }).toPromise()
          .then(
                result=>{
                   resolve(result);
                },
                error => { 
                  reject(error);
                }
          );
    });
    return promise;
  }
}
