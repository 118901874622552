import { ErrorHandler, Injectable } from "@angular/core";
 import { AppinsightsService } from "../services/appinsights.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private appInsightService: AppinsightsService)
    {

    }
    handleError(error: any) {        
        if(error instanceof TypeError) return;
        
        console.error(error); 
        this.appInsightService.logException(error);     
           
    }
}
