import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import * as momenttz from 'moment-timezone';
import { AppinsightsService } from './appinsights.service';
import { clearTranslations, loadTranslations } from '@angular/localize';
import { $localize } from '@angular/localize/init';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  private _locale: string;
  private _timezone: string;
  private _isKarmaUnitTest: boolean = false;

  public defaultLocale = 'en';
  public defaultTimezone = 'America/Phoenix';

  public localeKeyName = 'ApplicationLocale';
  public timeZoneKeyName = 'ApplicationTimezone';

  static supprtedLocales = new Map<string, string>([
    ["en", "en-CA"],
    ["fr", "fr-CA"],
    ["de", "de-CH"],
    ["es", "es-DO"]]);      

  public get locale(): string {
    return $localize.locale.split('-')[0];
  }

  public get timezone(): string {
    return this._timezone;
  }

  public get IsKarmaUnitTest(): boolean {
    return this._isKarmaUnitTest;
  }

  public set IsKarmaUnitTest(value) {
    this._isKarmaUnitTest = value;
  }

  constructor(private readonly appInsightService: AppinsightsService) { }

  static setLocale(locale: string, altCode?: string): Promise<void> {

    if (locale == null)
      locale = "en";

    clearTranslations();

    
    var locale_value = this.supprtedLocales.get(locale);    

    return fetch(`/assets/i18n/${locale}/${locale}.json`)
      .then(response => response.json())
      .catch(error => console.error(error))
      .then(response => {

        if (response)
          loadTranslations(response.translations);

        LocaleService.loadLocale(locale);
        
        $localize.locale = locale_value;
        

      }).catch((e) => {

        console.log('Error in load locale: ' + e);
        return fetch(`/assets/i18n/en/en.json`)
          .then(response => response.json())
          .catch(error => console.error(error))
          .then(response => {
            //$localize.locale = locale;    

            if (response)
              loadTranslations(response.translations);
          });
      });
  }
  
  public setTimezone(timezone: string) {
    if (timezone && !momenttz.tz.zone(timezone)) {
      const errorMessage = `Unable to configure the application with timezone: ${timezone}. Hence falling back to ${this.defaultTimezone} timezone`;
      console.error(errorMessage);
      this.appInsightService.logInfo(errorMessage);
      this._timezone = this.defaultTimezone;
      return;
    }

    this._timezone = timezone;
  }

  static loadLocale(locale: string): Promise<any> {
    try {
      

      var locale_value = this.supprtedLocales.get(locale);
      return import(`../../../node_modules/@angular/common/locales/${locale_value}.mjs`).then((module) => {
        registerLocaleData(module.default);
      });
    } catch (e) {
      throw new Error(`\r\nUnable to import module ${locale_value}.mjs`)
    }
  }
}
