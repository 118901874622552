import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';
import { DateService } from 'src/app/services/date.service';
import { maxQtyValue, maxRateValue } from '../../constants/rfp-proposal-constants';

@Component({
  selector: 'app-grid-tooltip',
  templateUrl: './grid-tooltip.component.html',
  styleUrls: ['./grid-tooltip.component.scss']
})
export class GridTooltipComponent implements ITooltipAngularComp {
  private params!: ITooltipParams;
  public valueToDisplay!: string;
  public isValid: boolean;

  constructor(private readonly dateService: DateService) { }

  agInit(params: ITooltipParams<any, any>): void {
    
    //TODO: AA Fix the language service and make it work
    //let lang = this.translationService.lang == "en" ? "" : this.translationService.lang + "-";

    let lang = "";

    this.params = params;
    
    if (this.params.colDef.headerName === lang + "Qty" && params.node.id !=='b-0') {
      if ((this.params.value < 0 || this.params.value >= maxQtyValue) || (this.params.value - Math.floor(this.params.value)) !== 0) {
        this.isValid = true;
        this.valueToDisplay = $localize `Please enter a valid number.`;
      }
    }

    if(this.params.colDef.headerName === lang + "Room Size" || this.params.colDef.headerName === lang + "Attendees") {
      if ((this.params.value.value === null) || (this.params.value.value < 0 || this.params.value.value >= maxRateValue) || (this.params.value.value - Math.floor(this.params.value.value)) !== 0) {
        this.isValid = true;
        this.valueToDisplay = $localize `Please enter a valid number.`;
      }
    }

    if (this.params.colDef.headerName === lang + "Rate" && params.node.id !=='b-0') {
      if ((this.params.value < 0 || this.params.value >= maxRateValue)) {
        this.isValid = true;
        this.valueToDisplay = $localize `Please enter a valid number.`;
      }
    }

    if (this.params.colDef.headerName === lang + "Start Date") {
      if (this.params.value.value === null || this.params.value.value === '') {
        this.isValid = true;
        this.valueToDisplay = $localize `Start Date should not be empty.`;
      }
    }

    if (this.params.colDef.headerName === lang + "End") {
      if (this.params.value < this.params.data.startTime) {
        this.isValid = true;
        this.valueToDisplay = $localize `End Time should be greater than Start Time.`;
      }
    }

    var count: number = 0;
    if (this.params.colDef.headerName === lang + "Date") {
      this.dateService.getProposalGuestroomDates().forEach(element => {
        if (this.params.value === element.localizedStartDate) {
          count += 1;
        }
      });
      if (count > 1 && !this.params.data.isValid && this.params.data.isValid != undefined) {
        this.isValid = true;
        this.valueToDisplay = $localize `Guestroom requirements cannot have two rows with the same date.`;
      }
    }

  }

  ngOnInit(): void {
  }

}
