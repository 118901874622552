import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { LocaleService } from '../services/locale.service';
import * as moment from 'moment';

@Injectable()
@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {

  constructor(private readonly localeService: LocaleService) { }

  transform(value: string, locale?: string, dateTimeFormatOptions?: Intl.DateTimeFormatOptions): string {
    return value ? moment.utc(value).toDate().toLocaleString(locale || this.localeService.locale, { timeZone: this.localeService.timezone, ...dateTimeFormatOptions}) : null;
  }

}
