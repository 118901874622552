<div class="row bg-primary" style="position: sticky !important;top:20px;">
  <div class="col-12">
    <div class="actions-header d-flex">
      <div class="col-12">
        <button *ngIf="showBackButton" class="back-button" id="btnBack" (click)="onBackButtonClicked()">
          <span class="fa fa-angle-left pe-2"></span>
          <a i18n>Back</a>
        </button>
        <span class="sub-header-title name-header ps-4" id="subHeaderTitleText">{{ subHeaderTitle }}</span>
      </div>
    </div>
  </div>
</div>

