import { MbRole } from "./mb-role"
 
export const PageRoles : Record<string, MbRole[]> = {
    ['Home']:  [ MbRole.Director, MbRole.LocAdmin, MbRole.Manager, MbRole.SubAdmin, MbRole.Supervisor ],
    ['RfpList']:  [ MbRole.Director, MbRole.LocAdmin, MbRole.Manager, MbRole.SubAdmin, MbRole.Supervisor ],
    ['MessageList']:  [ MbRole.Director, MbRole.LocAdmin, MbRole.Manager, MbRole.SubAdmin, MbRole.Supervisor ]
}

export enum Features {
    Home = 'Home',
    RfpList = 'RfpList',
    MessageList = 'MessageList'
}