import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  private helpTopic: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public helpTopic$: Observable<string> = this.helpTopic.asObservable();

  constructor() { }

  setHelpTopic(helpTopic: string) {
    //Prevents the "expression changed after it has been checked" error in development mode.
    //Usually not a good practice, but in this case the data isn't part of the business workflow
    //and poses no risk of presenting inconsistent data to the end user.
    setTimeout(() => {
      this.helpTopic.next(helpTopic);
    }, 0);  
  }
}