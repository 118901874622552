import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, ReplaySubject } from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { SearchSubscriptionResponseModel, SubscriptionContext, SubscriptionContextContract } from '../models/subscription-list.model';
import { ERRORCODESTOLOG, ErrorCode } from '../models/error';
import { SubscriptionListServiceUrlPath } from '../utils/constants';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionListService {
  
  public subscriptions$ = new ReplaySubject<SearchSubscriptionResponseModel>(1);
  public areSubsLoading$ = new Subject<boolean>();
  public errors$ = new Subject<any>();

  // subject / observable used in app.component.html *ngIf to control app.component rendering
  private _isSubscriptionSetSubject = new BehaviorSubject<boolean>(this.getSubscriptionStatus());
  public isSubscriptionSet$: Observable<boolean> = this._isSubscriptionSetSubject.asObservable();

  // Subscription Context 
  private _selectedSubscriptionSubject = new BehaviorSubject<SubscriptionContext>(this.getSubscriptionContext());
  public selectedSubscription$: Observable<SubscriptionContext> = this._selectedSubscriptionSubject.asObservable();

  constructor(private httpClient: HttpClient) {
    this._isSubscriptionSetSubject.next(this.getSubscriptionStatus());
    this._selectedSubscriptionSubject.next(this.getSubscriptionContext());
  }

  searchSubscriptions(name: string = ''): void {
    this.areSubsLoading$.next(true);
    this.httpClient.get<SearchSubscriptionResponseModel>(`${sessionStorage.getItem("MeetingBrokerBaseUrl")}${SubscriptionListServiceUrlPath}?limit=100`)
    .subscribe(x => {
      // Don't think we should ever get errors back with a 200 series response
      // non-200 responses will get caught and handled in the error block below.
      this.subscriptions$.next(x);
      this.areSubsLoading$.next(false);
    },
    (err) => {
      this.areSubsLoading$.next(false);

      if(ERRORCODESTOLOG.includes(err.status)) {
        console.log(JSON.stringify(err.error.errors));
      }
      
      switch(err.error.errors[0].code) {
        case ErrorCode.BAD_REQUEST: this.errors$.next({ translateKey: 'common-messages-txt-badrequestmessage', defaultText: 'Oops, something went wrong. Please check your input.' }); 
          break;
        case ErrorCode.INTERNAL_SERVER_ERROR: this.errors$.next({ translateKey: 'common-messages-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' }); 
          break;
        default: this.errors$.next({ translateKey: 'common-messages-txt-internalservererror', defaultText: 'Something went wrong. Please try again later.' });
      }
    });
  }

  setSubscriptionStatus(bool: boolean): void {
    sessionStorage.setItem('IsSubscriptionSet', `${bool}`);
    this._isSubscriptionSetSubject.next(bool);
  }

  setSelectedSubscription(selectedSubscription: SubscriptionContextContract): void {    
    this._selectedSubscriptionSubject.next(new SubscriptionContext(selectedSubscription));
    sessionStorage.setItem('SubscriptionContext', btoa(JSON.stringify(this._selectedSubscriptionSubject.value)));
  }

  private getSubscriptionStatus(): boolean {
    return sessionStorage.getItem('IsSubscriptionSet') === 'true';
  }

  private getSubscriptionContext(): SubscriptionContext {
    let subContext = sessionStorage.getItem('SubscriptionContext');
    if (subContext) {
      let userInfo: SubscriptionContext = JSON.parse(atob(subContext));
      return userInfo;
    }
    return {} as SubscriptionContext;
  }

}
