<div *ngIf="isHeaderRequired" class="modal-header" [class.df-warning-modal]="type === 'warning'"
  [class.df-info-modal]="type === 'info'" [class.df-error-modal]="type === 'error'">
  <span aria-hidden="true" class="alert-modal-icon me-4" [class.icon-exclamation-triangle]="type === 'warning'"
    [class.icon-info-circle]="type === 'info'" [class.icon-minus-circle]="type === 'error'">
  </span>
  <h2 [id]="headingId" class="modal-title">{{ title }}</h2>
  <button type="button" class="btn-close" aria-label="Close Vertically centered modal" (click)="cancel()">
  </button>
</div>
<div class="modal-body">
  <p>{{ message }}</p>
</div>
<div class="modal-footer" *ngIf="isFooterVisible">
  <div *ngIf="isCancelButtonVisible">
    <button id="cancel-alert-button" type="button" class="btn btn-outline-primary me-5"
      [disabled]="!isCancelButtonEnabled" (click)="cancel()">{{ cancelButtonText }}</button>
  </div>
  <button id="ok-alert-button" type="button" class="btn btn-primary" (click)="ok()">{{ okButtonText }}</button>
</div>