import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle: string = "";
  @Input() navigationUrl: string = "";
  @Output() backClicked = new EventEmitter<void>();
  constructor(private router: Router) {}

  ngOnInit(): void {}
  onBack() {
    if (this.navigationUrl === "") {
      this.backClicked.emit();
    } else {
      this.router.navigate([this.navigationUrl]);
    }
  }
}
