<nav class="navbar mb-3 bg-primary" *ngIf="isSubscriptionSet">
    <div class="hamburger-brand">
      <div class="hamburger-button-wrapper">
        <button id="hamburger-button" class="align-items-start btn-no-border-shadow bg-primary" type="button" style="border-radius: 20px;"
          (click)="sideNavService.toggleSideNav()" aria-controls="collapseExample">
          <span id="togglerIcon" class="navbar-toggler-icon"></span>
        </button>
    </div>
      <a class="navbar-brand">
        <div class="df-amadeuslogo-white df-logo-dim"><span class="visually-hidden">Amadeus</span></div>
        <div class="ms-4">MeetingBroker</div>
      </a>
    </div>
    <div id="helpButtonWrapper" class="header-control">
      <button id="helpButton"  type="button" (click)="handleOnHelpClick()">
        <span class="icon-question df-icon-solid help-icon"></span>
      </button>
    </div>
    <div>
      <!-- Links -->
      <div id="header-user-display-container" ngbDropdown placement="bottom-right" tabindex="0"
        class="d-inline-block header-user-container">
        <section id="header-user-display" ngbDropdownToggle class="d-flex flex-row header-display">
          <div id="initials" class="user-initials-container">
            {{user.firstName.charAt(0).toUpperCase()}}{{user.lastName.charAt(0).toUpperCase()}}
          </div>
          <div class="user-name-container">{{ this.user.firstName }}</div>
        </section>
        <div ngbDropdownMenu aria-labelledby="header-user-display" class="w-100">
          <button *ngFor="let item of accountDisplays" class="dropdown-item" ngbDropdownItem
            (click)="listenAccountItemClick(item)">
            {{item.label}}
          </button>
        </div>
      </div>
    </div>
  </nav>

  <div class="help">
    <iframe #iframe id="helpFrame" class="help-content"  [ngStyle]="{'display': !isHelpCollapsed ? 'block' : 'none'}"  sandbox="allow-same-origin allow-scripts" title="MeetingBroker Help"></iframe>
  </div>