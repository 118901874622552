import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SubHeaderActions } from 'src/app/models/subheader-actions.model';

@Component({
  selector: 'app-page-subheader',
  templateUrl: './page-subheader.component.html',
  styleUrls: ['./page-subheader.component.scss']
})
export class PageSubheaderComponent implements OnInit {

  @Input() actions: SubHeaderActions[] = [];
  @Input() showBackButton: boolean = true;
  @Input() showSubHeaderTitle: boolean = true;
  @Input() subHeaderTitle: string;

  @Output() backButtonClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  onBackButtonClicked() {
    this.backButtonClicked.emit();
  }

}
